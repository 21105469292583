import { Avatar, AvatarProps } from '@mui/material'

export const Avatar169 = (props: AvatarProps) => {
    return (
        <Avatar
            {...props}
            sx={{
                aspectRatio: '16/9',
                width: 56,
                '& img': {
                    aspectRatio: '16/9'
                },
                ...props.sx
            }}
            variant={'square'}
        />

    )
}