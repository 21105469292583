import type { useSlideShowContextType, useSlideShowDispatchType, useSlideShowType } from "./types";

import React from "react";

import { useClient, useUi } from "../../AppProvider";

export const SlideShowContext = React.createContext<useSlideShowContextType>({} as useSlideShowContextType);

export const useSlideShow: useSlideShowType = (initial) => {
    const SliderRef = React.useRef<HTMLDivElement | null>(null);
    const { setZIndex } = useUi()
    const [progressions, setProgressions] = React.useState(initial.progressions ?? []);

    const listener = (message: any) => {
        if (message.controller === 'ConnectedUser' && message.action === 'addProgression') {
            if (message.wsCode === 200) {
                setProgressions(prev => prev.map(o => o.slideId === message.payload.slideId ? message.payload : o))
            } else if (message.wsCode === 201) {
                setProgressions(prev => [...prev, message.payload])
            }

        }
    }
    const { sendMessage } = useClient(listener)

    const [fullScreen, toggleFullScreen] = React.useReducer(o => !o, false);
    const [activeIndex, setActiveIndex] = React.useState<number>(0);

    const dispatch: useSlideShowDispatchType = React.useMemo(() => ({
        toggleFullScreen,

        goNext: () => setActiveIndex(o => o + 1),
        goPrev: () => setActiveIndex(o => o - 1),
        goTo: (index) => setActiveIndex(index),

    }), []);

    React.useEffect(() => {
        const minSwipeDistance = 50
        let touchStart: number | null = null
        let touchEnd: number | null = null

        const onKeyPress = ({ key }: KeyboardEvent) => {
            if (key === "ArrowRight" && activeIndex < initial.slides.length - 1) {
                dispatch.goNext();
            } else if (key === "ArrowLeft" && activeIndex > 0) {
                dispatch.goPrev();
            } else if (key === "Home") {
                dispatch.goTo(0);
            } else if (key === "End") {
                dispatch.goTo(initial.slides.length - 1);
            }
        }

        const onTouchStart = (e: TouchEvent) => {
            touchStart = e.touches[0].clientX
        }

        const onTouchMove = (e: TouchEvent) => {
            touchEnd = e.touches[0].clientX
        }

        const onTouchEnd = () => {
            if (!touchStart || !touchEnd) return
            const distance = touchStart - touchEnd
            const isLeftSwipe = distance > minSwipeDistance
            const isRightSwipe = distance < -minSwipeDistance
            if (isLeftSwipe && activeIndex < initial.slides.length - 1) dispatch.goNext()
            if (isRightSwipe && activeIndex > 0) dispatch.goPrev()

        }

        SliderRef.current?.focus()

        document.addEventListener("keyup", onKeyPress);
        SliderRef.current?.addEventListener("touchstart", onTouchStart);
        SliderRef.current?.addEventListener("touchmove", onTouchMove);
        SliderRef.current?.addEventListener("touchend", onTouchEnd);

        return () => {
            document.removeEventListener("keyup", onKeyPress);
            SliderRef.current?.removeEventListener("touchstart", onTouchStart);
            SliderRef.current?.removeEventListener("touchmove", onTouchMove);
            SliderRef.current?.removeEventListener("touchend", onTouchEnd);
        }

    }, [SliderRef.current, activeIndex, initial.slides]);

    React.useEffect(() => {
        let timer: NodeJS.Timeout | null = null
        const fetchProgress = () => {
            if (progressions?.find(o => o.slideId === initial.slides[activeIndex].id)) return
            sendMessage('ConnectedUser', 'addProgression', { documentId: initial.documentId, slideId: initial.slides[activeIndex].id, progress: 1 })
                .then(console.log)
        }
        if (SliderRef.current) {
            if (timer) clearTimeout(timer)
            timer = setTimeout(fetchProgress, 1000)
        } else {
            timer && clearTimeout(timer)
        }

        return () => { timer && clearTimeout(timer) }
    }, [activeIndex, initial.slides, SliderRef.current, progressions]);

    React.useEffect(() => {
        if (fullScreen) SliderRef.current?.classList.add('fullScreen')
        if (!fullScreen) SliderRef.current?.classList.remove('fullScreen')

        setZIndex(fullScreen ? 'unset' : 1100)

        return () => {
            setZIndex('unset')
        }
    }, [SliderRef.current, fullScreen]);

    return {
        SliderRef,

        fullScreen,

        slides: initial.slides,
        activeIndex,

        progressions,

        dispatch
    }
}