import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
//@ts-ignore
import SwipeableViews from 'react-swipeable-views-react-18-fix';
//@ts-ignore
import { autoPlay as autoPlayCpt } from 'react-swipeable-views-utils-react-18-fix';

const AutoPlaySwipeableViews = autoPlayCpt(SwipeableViews);

interface CarouselProps {
    images: string[]
    stepper?: 'dots' | 'text' | 'progress'
    autoPlay: boolean
}
function Carousel({ images, stepper, autoPlay }: CarouselProps) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    const Cpt = autoPlay ? AutoPlaySwipeableViews : SwipeableViews

    return (
        <Box sx={{ width: '100%', aspectRatio: '16/9' }}>
            <Cpt
                autoPlay={false}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {images.map((src, index) => (
                    <div key={src}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                                component="img"
                                sx={{
                                    display: 'block',
                                    overflow: 'hidden',
                                    width: '100%',
                                    aspectRatio: '16/9'
                                }}
                                src={`/download/${src}`}
                                alt={src}
                            />
                        ) : null}
                    </div>
                ))}
            </Cpt>
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                variant={stepper}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        Suivant
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Précédent
                    </Button>
                }
            />
        </Box>
    );
}

export default Carousel;