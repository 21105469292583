import React from 'react';
import { Avatar, Chip, ChipProps, ListItemButton, ListItemIcon, ListItemText, Menu, SxProps, Theme } from '@mui/material'
import { PersonInterface } from '../../types/authTypes';

/**
* @author Pierre Lucas
* @function PersonRep
**/


interface PersonRepProps {
    person: PersonInterface
    avatar?: boolean
    small?: boolean
    onDelete?: () => void
    onClick?: () => void
    onContextMenu?: () => void,
    handleDeleteLabel?: string
    sx?: SxProps<Theme> | undefined
    onConfirmDeleteRender?: JSX.Element | undefined
    contextMenu?: { items: contextMenuItemType[] } | undefined
    color?: ChipProps['color']
    variant?: ChipProps['variant']
}

type contextMenuItemType = {
    icon?: JSX.Element
    primary: string
    secondary?: string
    action: (object: PersonInterface) => void
}

const PersonRep = ({ person, avatar, small, onDelete, onClick, onContextMenu, sx, onConfirmDeleteRender, contextMenu, handleDeleteLabel, color, variant }: PersonRepProps) => {
    const [deleteRequested, setDeleteRequested] = React.useState<boolean>(false);
    const [confirmDeleted, setConfirmDeleted] = React.useState<boolean>(false)

    const [ctxMenu, setCtxMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    let timer1: ReturnType<typeof setTimeout> | null = null;
    let timer2: ReturnType<typeof setTimeout> | null = null;

    React.useEffect(() => {
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            if (timer1) clearTimeout(timer1)
            // eslint-disable-next-line react-hooks/exhaustive-deps
            if (timer2) clearTimeout(timer2)
        }
    }, [])

    const handleDeleteRequest = () => {
        if (!deleteRequested) {
            setDeleteRequested(true)
            timer1 = setTimeout(() => setDeleteRequested(false), 3000)
        } else {
            setConfirmDeleted(true)
            timer2 = setTimeout(() => onDelete!(), 200);
        }
    }
    const handleOnClick = () => {
        onClick!();
    }

    const handleCloseCtx = () => setCtxMenu(null)
    const handleCtxMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setCtxMenu(
            ctxMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : null,
        );
    };
    return (
        <React.Fragment>
            {contextMenu && <Menu
                open={ctxMenu !== null}
                onClose={handleCloseCtx}
                anchorReference="anchorPosition"
                anchorPosition={
                    ctxMenu !== null
                        ? { top: ctxMenu.mouseY, left: ctxMenu.mouseX }
                        : undefined
                }
            >
                {contextMenu.items.map((item, index) => <ListItemButton key={index} onClick={() => { item.action(person); handleCloseCtx() }}>
                    {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                    <ListItemText primary={item.primary} secondary={item.secondary ?? undefined} />
                </ListItemButton>)}
            </Menu>}
            {confirmDeleted && onConfirmDeleteRender
                ? onConfirmDeleteRender
                : <Chip
                    variant={variant}
                    onContextMenu={(typeof onContextMenu === 'function' && onContextMenu) || (contextMenu && handleCtxMenu) || undefined}
                    avatar={(avatar && <Avatar src={person.imageId ? `/download/${person.imageId}` : undefined} alt={`${person.firstname} ${person.lastname} `} />) || undefined}
                    label={!deleteRequested ? `${person.firstname} ${person.lastname} ` : (handleDeleteLabel ?? 'Cliquer pour supprimer')}
                    size={small ? 'small' : 'medium'}
                    clickable={deleteRequested}
                    onClick={(deleteRequested && handleDeleteRequest) || (typeof onClick === 'function' && handleOnClick) || undefined}
                    onDelete={(typeof onDelete === 'function' && !deleteRequested && handleDeleteRequest) || undefined}
                    sx={{ ml: 2, mr: 2, ...sx }}
                    color={deleteRequested ? 'error' : color}
                />}
        </React.Fragment>
    )
}
export default PersonRep