import { List, ListItem, ListItemText } from '@mui/material'
import React from 'react'
import { ComponentWithElementProps } from '../types'
import ElementSwitcher from './ElementSwitcher'

const ListContainer = ({ element }: ComponentWithElementProps) => {
    switch (element.listType) {
        case 'mui': return (
            <List {...element.props}>
                {element.childs.map(child => <MuiItem key={child.id} element={child} />)}
            </List>
        )
        default: return (
            <element.listType>
                {element.childs.map(child => child.type === 3
                    ? <ElementSwitcher key={child.id} element={child} />
                    : <Item key={child.id} element={child} />)}
            </element.listType>
        )
    }
}

const MuiItem = ({ element }: ComponentWithElementProps) => {
    return (
        <ListItem>
            <ListItemText {...element.props} />
        </ListItem>
    )
}
const Item = ({ element }: ComponentWithElementProps) => {
    return (
        <li>
            <ListItemText {...element.props} />
        </li>
    )
}

export default ListContainer