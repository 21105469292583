import React from 'react';
import ReactDOM from 'react-dom/client';
import AppClient from './AppClient';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    // <React.StrictMode>
    <AppClient />
    // </React.StrictMode>
)