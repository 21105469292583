import React from 'react'

import { InputAdornment, IconButton } from '@mui/material'
import { Close, Search as SearchIcon } from '@mui/icons-material'

import { useLangage, useSearch, useUi } from '../..'

import InputField from '../../../commons/InputField'

export const SearchField = () => {
    const { trad } = useLangage()
    const { open, toggleOpen } = useUi()
    const { search, setSearch, handleSearch, setResults } = useSearch()

    const handleDelete = () => {
        setSearch('')
        setResults(null)
    }

    const handleValidate = () => {
        handleSearch()
        if (open) toggleOpen()
    }

    return (
        <InputField
            label={trad.searchLabel}
            value={search}
            onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setSearch(value)}
            onKeyUp={(event: any) => event.key === 'Enter' && handleSearch()}
            fullWidth
            placeholder={trad.searchLabel}
            InputProps={{
                sx: { backgroundColor: theme => theme.palette.background.default },
                startAdornment: search.length <= 2 &&
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>,
                endAdornment: search.length > 2
                    ? <InputAdornment position="end" sx={{ mt: 1.5 }}>
                        <IconButton
                            onClick={handleValidate}
                            size='small'
                        >
                            <SearchIcon fontSize='small' />
                        </IconButton>
                        <IconButton
                            onClick={handleDelete}
                            size='small'
                        >
                            <Close fontSize='small' />
                        </IconButton>
                    </InputAdornment >
                    : undefined
            }}
        />
    )
}