import { Link } from "react-router-dom"

import { Box, Card, CardActionArea, CardContent, CardMedia, CircularProgress, CircularProgressProps, LinearProgress, SxProps, Tooltip, Typography } from "@mui/material"

interface FormationLike {
    id: string
    name: string
    imageId: string | null
    description: string
    userProgress?: { [k: string]: number, total: number }
    serviceProgress?: { [k: string]: number, total: number }
}


interface FormationRepProps<T extends FormationLike> {
    formation: T
    redirect?: string
    sx?: SxProps
    noRedirect?: boolean
    variant?: 'linear' | 'circular'
}
export const FormationRep = <T extends FormationLike>({ formation, redirect, sx = {}, noRedirect = false, variant = 'circular' }: FormationRepProps<T>) => {
    return (
        <Card sx={sx}>
            <CardActionArea
                component={noRedirect ? 'div' : Link}
                to={noRedirect ? undefined : redirect ?? `/formations/${formation.id}`}
                sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: 2,
                    alignItems: 'stretch'
                }}>
                {formation.imageId && <CardMedia image={`/download/${formation.imageId}`} sx={{ minWidth: 150, minHeight: 100 }} />}
                <CardContent sx={{ display: 'flex', flexWrap: 'nowrap', gap: 2, alignItems: 'center', flexGrow: 1 }}>
                    <Box flexGrow={1}>
                        <Typography variant="h6">{formation.name}</Typography>
                        <Typography variant="caption" color="GrayText" mb={0} whiteSpace={'break-spaces'}>{formation.description}</Typography>
                    </Box>
                    <Box>
                        {variant === 'circular' && (formation.userProgress && formation.serviceProgress) && <UserServiceProgress user={formation.userProgress.total} service={formation.serviceProgress.total} />}
                    </Box>
                </CardContent>
                {variant === 'linear' && <ProgressionRep user={formation.userProgress?.total} service={formation.serviceProgress?.total} />}
            </CardActionArea>
        </Card>
    )
}
interface UserServiceProgressProps {
    user: number
    service: number
}
export const UserServiceProgress = ({ user, service }: UserServiceProgressProps) => {
    const props: { color: CircularProgressProps['color'], value: number, title: string }[] = [
        { color: 'warning' as CircularProgressProps['color'], value: user, title: 'Ma progression' },
        { color: 'info' as CircularProgressProps['color'], value: service, title: 'La progression de mon service' }
    ].sort((a, b) => a.value < b.value ? -1 : 1)

    return (
        <Tooltip title={<Box>
            <Box display={'flex'} gap={1} alignItems={'center'}>
                <Box sx={{ backgroundColor: (theme) => theme.palette.warning.main, width: 10, height: 4 }}> </Box>
                <Box>Ma progression</Box>
                <Box>{Math.floor(100 * user)}%</Box>
            </Box>
            <Box display={'flex'} gap={1} alignItems={'center'}>
                <Box sx={{ backgroundColor: (theme) => theme.palette.info.main, width: 10, height: 4 }}> </Box>
                <Box>La progression de mon service</Box>
                <Box>{Math.floor(100 * service)}%</Box>
            </Box>
        </Box>}>
            <Box sx={{ position: 'relative', width: 50, height: 50 }}>
                <CircularProgress variant={'determinate'} value={props[0].value * 100} color={props[0].color} sx={{ position: 'absolute', top: 5, left: 5 }} thickness={10} />
                <CircularProgress variant={'determinate'} value={props[1].value * 100} color={props[1].color} sx={{ position: 'absolute', top: 5, left: 5 }} />
                <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="caption" color="GrayText" >
                        {Math.floor(user * 100)}%
                    </Typography>
                </Box>
            </Box >
        </Tooltip>
    )
}


interface ProgressionRepProps {
    user?: number
    service?: number
}
export const ProgressionRep = ({ user = 0, service = 0 }: ProgressionRepProps) => {
    return (
        (user || service)
            ? <Box sx={{ position: 'absolute', bottom: 0 }} width="100%">
                <Tooltip title="Ma progression">
                    <LinearProgress
                        value={100 * user}
                        variant={'determinate'}
                        color={user < 1 ? 'warning' : undefined} />
                </Tooltip>
                {service && <Tooltip title="La progression de mon service">
                    <LinearProgress
                        value={100 * service}
                        variant="determinate"
                        color={'info'} />
                </Tooltip>}

            </Box >
            : <></>
    )
}