import { Divider } from '@mui/material'
import React from 'react'
import { EditorElement } from '../types'
import GridContainer from './GridContainer'
import GridItem from './GridItem'
import ListContainer from './ListContainer'
import Typography from './Typography'
import Image from './Image'
import Video from './Video'
import Alert from './Alert'
import CheckList from './CheckList'
import Carousel from './Carousel'
import Table from './Table'
import OngletContainer from './OngletContainer'
import StepperContainer from './StepperContainer'
import DocLink from './DocLink'
import ExternalLink from './ExternalLink'

interface ElementSwitcherProps {
    element: EditorElement
}
const ElementSwitcher = ({ element }: ElementSwitcherProps) => {
    switch (element.type) {
        case 1: return (<GridContainer element={element} />)
        case 2: return (<GridItem element={element} />)
        case 3: return (<ListContainer element={element} />)
        case 4: case 5: return (<Typography element={element} />)
        case 6: return (<Divider />)
        case 7: return (<Image element={element} />)
        case 8: return (<Video element={element} />)
        case 9: return (<Alert element={element} />)
        case 10: return (<Carousel element={element} />)
        case 11: return (<CheckList element={element} />)
        case 14: return (<Table element={element} />)
        case 15: return (<DocLink element={element} />)
        case 16: return (<ExternalLink element={element} />)
        case 17: return (<StepperContainer element={element} />)
        case 19: return (<OngletContainer element={element} />)
        default: return (<>Aucun element pour type : {element.type}</>)
    }
}

export default ElementSwitcher