import React from 'react'

import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Menu, Stack, Tooltip } from '@mui/material'
import { Favorite, Logout, MenuBook } from '@mui/icons-material'

import { useLangage, useSecurity, useUser } from '../..'
import Common from './Common'
import { Link } from 'react-router-dom'

export const UserToolbar = () => {
    const user = useUser()
    const { trad } = useLangage()
    const { logout } = useSecurity()

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    if (!user) return null
    return (
        <React.Fragment>
            <Stack direction={'row'} gap={2}>
                {user.formations.length > 0 && <Tooltip title={trad.myFormations}>
                    <IconButton component={Link} to="/formations" color='inherit'>
                        <MenuBook color={'inherit'} />
                    </IconButton>
                </Tooltip>}
                {user.favorites.length > 0 && <Tooltip title={trad.favorites}>
                    <IconButton component={Link} to="/favorites">
                        <Favorite color={'error'} />
                    </IconButton>
                </Tooltip>}

                <Tooltip title={`${user.firstname} ${user.lastname}`}>
                    <IconButton sx={{ p: 0 }} onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>
                        <Avatar alt={`${user.firstname} ${user.lastname}`} src={user.imageId ? `/download/${user.imageId}` : undefined}>
                            {user.firstname.at(0)}{user.lastname.at(0)}
                        </Avatar>
                    </IconButton>
                </Tooltip>
            </Stack>

            <Menu
                sx={{ mt: 1.5 }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                onClick={() => setAnchorEl(null)}
            >
                <ListItem>
                    <ListItemAvatar>
                        <Avatar alt={`${user?.firstname} ${user?.lastname}`} src={user?.imageId ? `/download/${user.imageId}` : undefined} >
                            {user?.firstname.at(0)}{user?.lastname.at(0)}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`${user?.firstname} ${user?.lastname}`} secondary={user?.email} />
                </ListItem>
                <Common />
                <List dense sx={{ mb: -2 }}>
                    <ListItemButton onClick={logout}>
                        <ListItemIcon>
                            <Logout />
                        </ListItemIcon>
                        <ListItemText primary={trad.logout} />
                    </ListItemButton>
                </List>
            </Menu>

        </React.Fragment>
    )
}