import React from 'react'

import { Switch } from '@mui/material'

import { VideoPlayerContext } from '..'

export const AutoPlay = () => {
    const { autoplay, dispatch, sources } = React.useContext(VideoPlayerContext)
    if (!sources || sources.length === 1) return (<></>)
    return (
        <Switch checked={autoplay} onClick={dispatch.toggleAutoPlay} />
    )
}
