import type { SxProps, Theme } from "@mui/material"
import type { DocumentType } from "../types/documents"

import { SvgIcon, Tooltip } from "@mui/material"
import { useUi } from "../AppProvider"

interface DocumentLogoProps {
    type: DocumentType
    sx?: SxProps<Theme>
    tooltip?: boolean
}
export const DocumentLogo = ({ type, sx = {}, tooltip }: DocumentLogoProps) => {
    const { theme } = useUi()
    switch (type) {
        case 1: return <LogoProtocole sx={sx} {...{ tooltip }} fill={{ primary: theme.palette.primary.main }} />
        case 2: return <LogoCours sx={sx} {...{ tooltip }} fill={{ primary: theme.palette.primary.main }} />
        case 3: return <LogoArticle sx={sx} {...{ tooltip }} fill={{ primary: theme.palette.primary.main }} />
        case 4: return <LogoWebinaire sx={sx} {...{ tooltip }} fill={{ primary: theme.palette.primary.main }} />
        case 5: return <LogoCasClinique sx={sx} {...{ tooltip }} fill={{ primary: theme.palette.primary.main }} />
        case 6: return <LogoOutil sx={sx} {...{ tooltip }} fill={{ primary: theme.palette.primary.main }} />
    }
}

interface LogoProps {
    sx: SxProps<Theme>
    tooltip?: boolean
    fill?: { primary: string, secondary?: string }
}

export const LogoArticle = ({ sx, tooltip, fill }: LogoProps) => {
    return (
        tooltip
            ? <Tooltip title={`Article`}>
                <SvgIcon sx={sx} viewBox="-1 0 17 17">
                    <path style={{ fill: fill?.primary ?? '#E7E7E7' }} d="M26.678-86.353H23.6V-91.9a1.85,1.85,0,0,0-1.849-1.849h-7.4A1.85,1.85,0,0,0,12.5-91.9v12.329a3.079,3.079,0,0,0,.9,2.179,3.079,3.079,0,0,0,2.179.9h9.247a2.466,2.466,0,0,0,1.744-.722,2.466,2.466,0,0,0,.722-1.744v-6.781a.617.617,0,0,0-.18-.436.617.617,0,0,0-.436-.18Zm-6.164,4.315H15.582V-83.27h4.932Zm0-2.466H15.582v-1.233h4.932Zm0-2.466H15.582V-88.2h4.932Zm5.548,8.014a1.233,1.233,0,0,1-1.233,1.233A1.233,1.233,0,0,1,23.6-78.955V-85.12h2.466Z" transform="translate(-12.5 93.75)" fill="#b2b2b2" />
                </SvgIcon>
            </Tooltip>
            : <SvgIcon sx={sx} viewBox="-1 0 17 17">
                <path style={{ fill: fill?.primary ?? '#E7E7E7' }} d="M26.678-86.353H23.6V-91.9a1.85,1.85,0,0,0-1.849-1.849h-7.4A1.85,1.85,0,0,0,12.5-91.9v12.329a3.079,3.079,0,0,0,.9,2.179,3.079,3.079,0,0,0,2.179.9h9.247a2.466,2.466,0,0,0,1.744-.722,2.466,2.466,0,0,0,.722-1.744v-6.781a.617.617,0,0,0-.18-.436.617.617,0,0,0-.436-.18Zm-6.164,4.315H15.582V-83.27h4.932Zm0-2.466H15.582v-1.233h4.932Zm0-2.466H15.582V-88.2h4.932Zm5.548,8.014a1.233,1.233,0,0,1-1.233,1.233A1.233,1.233,0,0,1,23.6-78.955V-85.12h2.466Z" transform="translate(-12.5 93.75)" fill="#b2b2b2" />
            </SvgIcon>
    )
}
export const LogoCasClinique = ({ sx, tooltip, fill }: LogoProps) => {
    return (
        tooltip
            ? <Tooltip title={`Cas clinique`}>
                <SvgIcon sx={sx} viewBox="0 0 13.795 19.707">
                    <g id="Group_234" data-name="Group 234" transform="translate(-17.187 96.874)">
                        <path style={{ fill: fill?.primary ?? '#E7E7E7' }} d="M30.864-91.343c-.01-.03-.023-.059-.036-.089,0,0,0,0,0-.007s-.01-.03-.02-.043a1.641,1.641,0,0,0-.3-.424l-4.49-4.49a1.581,1.581,0,0,0-.473-.325s-.007-.007-.01-.007a.061.061,0,0,0-.023-.007h0s-.01,0-.013-.007a1.5,1.5,0,0,0-.64-.135H18.83a1.643,1.643,0,0,0-1.642,1.642V-78.81a1.643,1.643,0,0,0,1.642,1.642H29.34a1.643,1.643,0,0,0,1.642-1.642V-90.742a1.545,1.545,0,0,0-.118-.6Zm-1.005-.276H26.712a.988.988,0,0,1-.985-.985v-3.147Zm-6.1,1.314a1.972,1.972,0,0,1,1.971,1.971,1.972,1.972,0,0,1-1.971,1.971,1.972,1.972,0,0,1-1.971-1.971A1.972,1.972,0,0,1,23.756-90.305Zm3.613,9.2a1.949,1.949,0,0,1-.792,1.577,1.944,1.944,0,0,1-1.179.394H22.114a1.944,1.944,0,0,1-1.179-.394,1.949,1.949,0,0,1-.792-1.577v-.985a3.615,3.615,0,0,1,3.613-3.613,3.615,3.615,0,0,1,3.613,3.613Z" transform="translate(0)" fill="#b2b2b2" />
                    </g>
                </SvgIcon>
            </Tooltip>
            : <SvgIcon sx={sx} viewBox="0 0 13.795 19.707">
                <g id="Group_234" data-name="Group 234" transform="translate(-17.187 96.874)">
                    <path style={{ fill: fill?.primary ?? '#E7E7E7' }} d="M30.864-91.343c-.01-.03-.023-.059-.036-.089,0,0,0,0,0-.007s-.01-.03-.02-.043a1.641,1.641,0,0,0-.3-.424l-4.49-4.49a1.581,1.581,0,0,0-.473-.325s-.007-.007-.01-.007a.061.061,0,0,0-.023-.007h0s-.01,0-.013-.007a1.5,1.5,0,0,0-.64-.135H18.83a1.643,1.643,0,0,0-1.642,1.642V-78.81a1.643,1.643,0,0,0,1.642,1.642H29.34a1.643,1.643,0,0,0,1.642-1.642V-90.742a1.545,1.545,0,0,0-.118-.6Zm-1.005-.276H26.712a.988.988,0,0,1-.985-.985v-3.147Zm-6.1,1.314a1.972,1.972,0,0,1,1.971,1.971,1.972,1.972,0,0,1-1.971,1.971,1.972,1.972,0,0,1-1.971-1.971A1.972,1.972,0,0,1,23.756-90.305Zm3.613,9.2a1.949,1.949,0,0,1-.792,1.577,1.944,1.944,0,0,1-1.179.394H22.114a1.944,1.944,0,0,1-1.179-.394,1.949,1.949,0,0,1-.792-1.577v-.985a3.615,3.615,0,0,1,3.613-3.613,3.615,3.615,0,0,1,3.613,3.613Z" transform="translate(0)" fill="#b2b2b2" />
                </g>
            </SvgIcon>
    )
}

export const LogoCours = ({ sx, tooltip, fill }: LogoProps) => {
    return (
        tooltip
            ? <Tooltip title={`Cours`}>
                <SvgIcon sx={sx} viewBox="0 0 17.091 12.608">
                    <g id="Group_262" data-name="Group 262" transform="translate(-9.375 81.25)">
                        <path style={{ fill: fill?.primary ?? '#E7E7E7' }} d="M12.662-69.418H9.375v-9.86h.657v8.545a.658.658,0,0,0,.192.465.658.658,0,0,0,.465.192h3.1a2.056,2.056,0,0,1,.348,0,3.287,3.287,0,0,1,2.176,1.413,8.446,8.446,0,0,0-3.648-.756Zm4.6-.131-.059-.066a4.509,4.509,0,0,0-3.418-1.775H11.347v-9.86h2.439c1.7,0,3.129,1.6,3.418,3.8a.214.214,0,0,0,0,.072,5.426,5.426,0,0,1,.059.73Zm1.374-.066-.059.066v-7.1a5.281,5.281,0,0,1,.046-.73.214.214,0,0,0,0-.072c.3-2.2,1.735-3.8,3.431-3.8h2.439v9.86H22.055a3.755,3.755,0,0,0-.48.033h-.151a4.521,4.521,0,0,0-2.787,1.742Zm7.829.2H23.179a8.544,8.544,0,0,0-3.648.776,3.34,3.34,0,0,1,2.176-1.433,2.056,2.056,0,0,1,.348,0h3.1a.658.658,0,0,0,.465-.192.658.658,0,0,0,.192-.465v-8.545h.657Z" fill="#b2b2b2" />
                    </g>
                </SvgIcon>
            </Tooltip>
            : <SvgIcon sx={sx} viewBox="0 0 17.091 12.608">
                <g id="Group_262" data-name="Group 262" transform="translate(-9.375 81.25)">
                    <path style={{ fill: fill?.primary ?? '#E7E7E7' }} d="M12.662-69.418H9.375v-9.86h.657v8.545a.658.658,0,0,0,.192.465.658.658,0,0,0,.465.192h3.1a2.056,2.056,0,0,1,.348,0,3.287,3.287,0,0,1,2.176,1.413,8.446,8.446,0,0,0-3.648-.756Zm4.6-.131-.059-.066a4.509,4.509,0,0,0-3.418-1.775H11.347v-9.86h2.439c1.7,0,3.129,1.6,3.418,3.8a.214.214,0,0,0,0,.072,5.426,5.426,0,0,1,.059.73Zm1.374-.066-.059.066v-7.1a5.281,5.281,0,0,1,.046-.73.214.214,0,0,0,0-.072c.3-2.2,1.735-3.8,3.431-3.8h2.439v9.86H22.055a3.755,3.755,0,0,0-.48.033h-.151a4.521,4.521,0,0,0-2.787,1.742Zm7.829.2H23.179a8.544,8.544,0,0,0-3.648.776,3.34,3.34,0,0,1,2.176-1.433,2.056,2.056,0,0,1,.348,0h3.1a.658.658,0,0,0,.465-.192.658.658,0,0,0,.192-.465v-8.545h.657Z" fill="#b2b2b2" />
                </g>
            </SvgIcon>
    )
}

export const LogoOutil = ({ sx, tooltip, fill }: LogoProps) => {
    return (
        tooltip
            ? <Tooltip title={`Outils`}>
                <SvgIcon sx={sx} viewBox="0 0 15.458 15.47">
                    <g id="Group_233" data-name="Group 233" transform="translate(-4.714 95.321)">
                        <path style={{ fill: fill?.primary ?? '#E7E7E7' }} d="M6.055-95.265l3.088,2.009h0a.353.353,0,0,1,.162.276l.037.771h0a.349.349,0,0,0,.1.231l1.9,1.9a.348.348,0,0,1,.1.253.347.347,0,0,1-.111.248l-.927.867a.348.348,0,0,1-.487-.01L8.057-90.593a.347.347,0,0,0-.229-.1l-.772-.037a.348.348,0,0,1-.275-.163L4.771-93.979a.352.352,0,0,1,.045-.437l.4-.4.4-.4a.35.35,0,0,1,.435-.044Zm-.431,15.21a.7.7,0,0,0,.987,0L12.362-85.8a.345.345,0,0,1,.244-.1.346.346,0,0,1,.244.1l1.138,1.138h0a.346.346,0,0,1,0,.488.346.346,0,0,0,0,.487l2.724,2.734A3.711,3.711,0,0,0,19.7-79.879l.133-.013h0a.347.347,0,0,0,.311-.311l.013-.135h0a3.713,3.713,0,0,0-1.071-2.984L16.346-86.06a.348.348,0,0,0-.488,0,.347.347,0,0,1-.487,0L14.233-87.2h0a.348.348,0,0,1,0-.488l.533-.531a.347.347,0,0,1,.375-.077,3.638,3.638,0,0,0,2.615.057,3.64,3.64,0,0,0,1.978-1.711,3.644,3.644,0,0,0,.321-2.6.347.347,0,0,0-.243-.251.347.347,0,0,0-.337.088l-2,2a.346.346,0,0,1-.487,0l-1.421-1.421a.342.342,0,0,1-.1-.244.341.341,0,0,1,.1-.243l2-2a.344.344,0,0,0,.089-.338.344.344,0,0,0-.252-.243,3.642,3.642,0,0,0-2.594.321A3.642,3.642,0,0,0,13.1-92.894a3.639,3.639,0,0,0,.055,2.613.344.344,0,0,1-.075.377L4.919-81.747a.7.7,0,0,0-.2.493.7.7,0,0,0,.2.493Z" fill="#b2b2b2" />
                    </g>
                </SvgIcon>
            </Tooltip>
            : <SvgIcon sx={sx} viewBox="0 0 15.458 15.47">
                <g id="Group_233" data-name="Group 233" transform="translate(-4.714 95.321)">
                    <path style={{ fill: fill?.primary ?? '#E7E7E7' }} d="M6.055-95.265l3.088,2.009h0a.353.353,0,0,1,.162.276l.037.771h0a.349.349,0,0,0,.1.231l1.9,1.9a.348.348,0,0,1,.1.253.347.347,0,0,1-.111.248l-.927.867a.348.348,0,0,1-.487-.01L8.057-90.593a.347.347,0,0,0-.229-.1l-.772-.037a.348.348,0,0,1-.275-.163L4.771-93.979a.352.352,0,0,1,.045-.437l.4-.4.4-.4a.35.35,0,0,1,.435-.044Zm-.431,15.21a.7.7,0,0,0,.987,0L12.362-85.8a.345.345,0,0,1,.244-.1.346.346,0,0,1,.244.1l1.138,1.138h0a.346.346,0,0,1,0,.488.346.346,0,0,0,0,.487l2.724,2.734A3.711,3.711,0,0,0,19.7-79.879l.133-.013h0a.347.347,0,0,0,.311-.311l.013-.135h0a3.713,3.713,0,0,0-1.071-2.984L16.346-86.06a.348.348,0,0,0-.488,0,.347.347,0,0,1-.487,0L14.233-87.2h0a.348.348,0,0,1,0-.488l.533-.531a.347.347,0,0,1,.375-.077,3.638,3.638,0,0,0,2.615.057,3.64,3.64,0,0,0,1.978-1.711,3.644,3.644,0,0,0,.321-2.6.347.347,0,0,0-.243-.251.347.347,0,0,0-.337.088l-2,2a.346.346,0,0,1-.487,0l-1.421-1.421a.342.342,0,0,1-.1-.244.341.341,0,0,1,.1-.243l2-2a.344.344,0,0,0,.089-.338.344.344,0,0,0-.252-.243,3.642,3.642,0,0,0-2.594.321A3.642,3.642,0,0,0,13.1-92.894a3.639,3.639,0,0,0,.055,2.613.344.344,0,0,1-.075.377L4.919-81.747a.7.7,0,0,0-.2.493.7.7,0,0,0,.2.493Z" fill="#b2b2b2" />
                </g>
            </SvgIcon>
    )
}

export const LogoProtocole = ({ sx, tooltip, fill }: LogoProps) => {
    return (
        tooltip
            ? <Tooltip title={`Procédure`}>
                <SvgIcon sx={sx} viewBox=".5 0 13.795 18.351">
                    <g id="Group_235" data-name="Group 235" transform="translate(-16.172 95)">
                        <path style={{ fill: fill?.primary ?? '#E7E7E7' }} d="M28.357-81.223h-.918a2.2,2.2,0,0,1-2.2,2.078H20.894A2.2,2.2,0,0,1,18.7-81.222h-.918a1.614,1.614,0,0,0-1.61,1.61v12.322a1.614,1.614,0,0,0,1.61,1.61H28.357a1.614,1.614,0,0,0,1.61-1.61V-79.613a1.615,1.615,0,0,0-1.61-1.61Zm-10,3.974a.408.408,0,0,1,.565.113l.305.458,1.047-1.1a.408.408,0,0,1,.577-.014.408.408,0,0,1,.014.577L19.67-75.964a.654.654,0,0,1-.473.208l-.053,0a.649.649,0,0,1-.483-.293l-.421-.632a.408.408,0,0,1,.114-.565Zm2.509,6.441-1.191,1.253a.653.653,0,0,1-.473.208l-.054,0a.647.647,0,0,1-.483-.294l-.421-.632a.408.408,0,0,1,.113-.565.408.408,0,0,1,.565.113l.306.458,1.047-1.1a.408.408,0,0,1,.577-.014.409.409,0,0,1,.015.577Zm0-3.285-1.191,1.253a.657.657,0,0,1-.473.209l-.054,0a.646.646,0,0,1-.483-.293l-.421-.631a.408.408,0,0,1,.113-.565.408.408,0,0,1,.565.113l.306.458,1.047-1.1a.408.408,0,0,1,.577-.014.407.407,0,0,1,.014.575Zm6.7,4.682H22.092a.408.408,0,0,1-.408-.408.408.408,0,0,1,.408-.408H27.56a.408.408,0,0,1,.408.408A.408.408,0,0,1,27.56-69.411Zm0-3.284H22.092a.408.408,0,0,1-.408-.408.408.408,0,0,1,.408-.408H27.56a.408.408,0,0,1,.408.408A.408.408,0,0,1,27.56-72.694Zm0-3.125H22.092a.408.408,0,0,1-.408-.408.408.408,0,0,1,.408-.408H27.56a.408.408,0,0,1,.408.408A.408.408,0,0,1,27.56-75.82Z" transform="translate(0 -10.968)" fill="#b2b2b2" />
                        <path style={{ fill: fill?.secondary ?? '#E7E7E77A' }} d="M34.325-91.029h4.35a1.284,1.284,0,0,0,1.283-1.283A1.284,1.284,0,0,0,38.676-93.6H37.657a.034.034,0,0,0,0-.01l.013-.033v-.19A1.174,1.174,0,0,0,36.5-95a1.172,1.172,0,0,0-1.172,1.172s.014.229.016.233H34.326a1.284,1.284,0,0,0-1.283,1.283,1.284,1.284,0,0,0,1.282,1.283Z" transform="translate(-13.431 0)" fill="#b2b2b2" />
                    </g>
                </SvgIcon>
            </Tooltip>
            : <SvgIcon sx={sx} viewBox=".5 0 13.795 18.351">
                <g id="Group_235" data-name="Group 235" transform="translate(-16.172 95)">
                    <path style={{ fill: fill?.primary ?? '#E7E7E7' }} d="M28.357-81.223h-.918a2.2,2.2,0,0,1-2.2,2.078H20.894A2.2,2.2,0,0,1,18.7-81.222h-.918a1.614,1.614,0,0,0-1.61,1.61v12.322a1.614,1.614,0,0,0,1.61,1.61H28.357a1.614,1.614,0,0,0,1.61-1.61V-79.613a1.615,1.615,0,0,0-1.61-1.61Zm-10,3.974a.408.408,0,0,1,.565.113l.305.458,1.047-1.1a.408.408,0,0,1,.577-.014.408.408,0,0,1,.014.577L19.67-75.964a.654.654,0,0,1-.473.208l-.053,0a.649.649,0,0,1-.483-.293l-.421-.632a.408.408,0,0,1,.114-.565Zm2.509,6.441-1.191,1.253a.653.653,0,0,1-.473.208l-.054,0a.647.647,0,0,1-.483-.294l-.421-.632a.408.408,0,0,1,.113-.565.408.408,0,0,1,.565.113l.306.458,1.047-1.1a.408.408,0,0,1,.577-.014.409.409,0,0,1,.015.577Zm0-3.285-1.191,1.253a.657.657,0,0,1-.473.209l-.054,0a.646.646,0,0,1-.483-.293l-.421-.631a.408.408,0,0,1,.113-.565.408.408,0,0,1,.565.113l.306.458,1.047-1.1a.408.408,0,0,1,.577-.014.407.407,0,0,1,.014.575Zm6.7,4.682H22.092a.408.408,0,0,1-.408-.408.408.408,0,0,1,.408-.408H27.56a.408.408,0,0,1,.408.408A.408.408,0,0,1,27.56-69.411Zm0-3.284H22.092a.408.408,0,0,1-.408-.408.408.408,0,0,1,.408-.408H27.56a.408.408,0,0,1,.408.408A.408.408,0,0,1,27.56-72.694Zm0-3.125H22.092a.408.408,0,0,1-.408-.408.408.408,0,0,1,.408-.408H27.56a.408.408,0,0,1,.408.408A.408.408,0,0,1,27.56-75.82Z" transform="translate(0 -10.968)" fill="#b2b2b2" />
                    <path style={{ fill: fill?.secondary ?? '#E7E7E77A' }} d="M34.325-91.029h4.35a1.284,1.284,0,0,0,1.283-1.283A1.284,1.284,0,0,0,38.676-93.6H37.657a.034.034,0,0,0,0-.01l.013-.033v-.19A1.174,1.174,0,0,0,36.5-95a1.172,1.172,0,0,0-1.172,1.172s.014.229.016.233H34.326a1.284,1.284,0,0,0-1.283,1.283,1.284,1.284,0,0,0,1.282,1.283Z" transform="translate(-13.431 0)" fill="#b2b2b2" />
                </g>
            </SvgIcon>
    )
}

export const LogoWebinaire = ({ sx, tooltip, fill }: LogoProps) => {
    return (
        tooltip
            ? <Tooltip title={`Webinaire`}>
                <SvgIcon sx={sx} viewBox="0 0 18.459 18.459">
                    <path id="Path_120" data-name="Path 120" d="M62.82-88.461v-.77A.77.77,0,0,0,62.052-90H57.437a.771.771,0,0,0-.769.769v3.077a.772.772,0,0,0,.769.769h4.615a.771.771,0,0,0,.769-.769v-.77l1.539,1.539V-90Z" transform="translate(-45.9 90)" fill="#b2b2b2" />
                    <path style={{ fill: fill?.primary ?? '#E7E7E7' }} d="M19.23-86.154V-90H11.539A1.543,1.543,0,0,0,10-88.461v12.306a1.544,1.544,0,0,0,1.539,1.539h5.384v1.538H14.615a1.538,1.538,0,0,0-1.539,1.539h9.23a1.538,1.538,0,0,0-1.538-1.539H18.461v-1.538h5.384a1.544,1.544,0,0,0,1.539-1.539v-7.691H21.537a2.31,2.31,0,0,1-2.307-2.307Zm-1.539,2.307a1.538,1.538,0,0,1,1.539,1.539,1.538,1.538,0,0,1-1.539,1.539,1.538,1.538,0,0,1-1.538-1.539A1.538,1.538,0,0,1,17.691-83.848Zm-3.076,6.923a2.308,2.308,0,0,1,2.307-2.307h1.539a2.308,2.308,0,0,1,2.307,2.307Z" transform="translate(-10 90)" fill="#b2b2b2" />
                </SvgIcon>
            </Tooltip>
            : <SvgIcon sx={sx} viewBox="0 0 18.459 18.459">
                <path id="Path_120" data-name="Path 120" d="M62.82-88.461v-.77A.77.77,0,0,0,62.052-90H57.437a.771.771,0,0,0-.769.769v3.077a.772.772,0,0,0,.769.769h4.615a.771.771,0,0,0,.769-.769v-.77l1.539,1.539V-90Z" transform="translate(-45.9 90)" fill="#b2b2b2" />
                <path style={{ fill: fill?.primary ?? '#E7E7E7' }} d="M19.23-86.154V-90H11.539A1.543,1.543,0,0,0,10-88.461v12.306a1.544,1.544,0,0,0,1.539,1.539h5.384v1.538H14.615a1.538,1.538,0,0,0-1.539,1.539h9.23a1.538,1.538,0,0,0-1.538-1.539H18.461v-1.538h5.384a1.544,1.544,0,0,0,1.539-1.539v-7.691H21.537a2.31,2.31,0,0,1-2.307-2.307Zm-1.539,2.307a1.538,1.538,0,0,1,1.539,1.539,1.538,1.538,0,0,1-1.539,1.539,1.538,1.538,0,0,1-1.538-1.539A1.538,1.538,0,0,1,17.691-83.848Zm-3.076,6.923a2.308,2.308,0,0,1,2.307-2.307h1.539a2.308,2.308,0,0,1,2.307,2.307Z" transform="translate(-10 90)" fill="#b2b2b2" />
            </SvgIcon>
    )
}