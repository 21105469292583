import React from 'react'

import { Avatar, Box, Typography } from '@mui/material'

import { useUser } from '../..'
import Common from './Common'

export const UserMenu = () => {
    const user = useUser()

    if (!user) return null
    return (
        <Box mb={1}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', alignItems: 'center', p: 2 }}>
                <Avatar sx={{ m: 2, width: 100, height: 100 }} alt={`${user.firstname} ${user.lastname}`} src={user.imageId ? `/download/${user.imageId}` : undefined}>
                    {user.firstname[0]}{user.lastname[0]}
                </Avatar>
                <Box textAlign={'center'}>
                    <Typography variant="subtitle1">{user.firstname} {user.lastname}</Typography>
                    <Typography variant="caption" color="GrayText">{user.email}</Typography>
                </Box>
            </Box>
            <Common />
        </Box>
    )
}