import { HorizontalRule, List } from "@mui/icons-material";

export const ListContainerConfig = {
    type: 3,
    label: 'Liste',
    icon: List,
    allowedBy: [2, 3],
    autoConfig: true,
    defaultProps: {
        dense: false,
        subheader: undefined
    }
}

export const ListItemConfig = {
    type: 12,
    label: 'Element de liste',
    icon: HorizontalRule,
    allowedBy: [3],
    autoConfig: false,
    defaultProps: {
        primary: '',
        secondary: ''
    }
}