import React from 'react'

import { Box, Button, LinearProgress, ListItemAvatar, ListItemText, Menu, MenuItem } from '@mui/material'
import { Check } from '@mui/icons-material'

import { SlideShowContext } from '.'
import Avatar169 from '../../commons/Avatar169'

export const SlidesAccess = () => {
    const { slides, progressions, dispatch, activeIndex } = React.useContext(SlideShowContext)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null)
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const rect = event.currentTarget.getBoundingClientRect()
        const x = event.clientX - rect.left
        const width = rect.right - rect.left
        const index = Math.round(x / width * (slides.length - 1))
        dispatch.goTo(index)
    }
    return (
        <Box className={'slidesAccess'} data-view={undefined}>
            <Box width={'100%'} display={'flex'} flexDirection={'row'} gap={2} alignItems={'center'}>
                <Box flexGrow={1}>

                    <LinearProgress
                        onClick={handleClick}
                        variant="determinate" value={activeIndex / (slides.length - 1) * 100}
                    />
                </Box>
                <Box>
                    <Button onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>
                        {activeIndex + 1}/{slides.length}
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        {slides.map((slide, index) => <MenuItem key={index} selected={index === activeIndex} onClick={() => dispatch.goTo(index)}>
                            <ListItemAvatar>
                                <Avatar169 src={`/download/${slide.fileId}`} />
                            </ListItemAvatar>
                            <ListItemText primary={`Diapositive ${index + 1}`} />
                            {progressions.find(p => p.slideId === slide.id) && <Check color={'primary'} />}
                        </MenuItem>)}
                    </Menu>
                </Box>
            </Box>
        </Box>
    )
}