import { TableView } from "@mui/icons-material";

export const TableConfig = {
    type: 14,
    label: 'Tableau',
    icon: TableView,
    allowedBy: [2, 9],
    autoConfig: true,
    defaultProps: {
        dense: false,
    }
}