import React from 'react'

import { Box } from '@mui/material'

import { SlideShowContext } from './useSlideShow'

export const SlideContainer = () => {
    const { activeIndex, slides } = React.useContext(SlideShowContext)
    return (
        <Box className="slide-container">
            {slides.map((slide, index) =>
                <Box key={index} role={index === activeIndex ? 'slide-show' : 'slide-hidden'}>
                    <img src={`/download/${slide.fileId}`} alt='slide' />
                </Box>)}
        </Box>
    )
}