import React from 'react'
import { Box } from '@mui/material'
import { ComponentWithElementProps } from '../types'
import { exposeDocument } from '../../../types/ExposeEntity'
import { AuthProvider } from '../../../auth/AuthProvider'
import { DocumentRep } from '../..'

const DocLink = ({ element }: ComponentWithElementProps) => {
    const [document, setDocument] = React.useState<exposeDocument>()
    const { cFetch } = React.useContext(AuthProvider)
    React.useEffect(() => {
        cFetch(`/api/documents/${element.documentId}`, 'GET').then(setDocument)
    }, [element])
    return (
        <Box>
            {document && <DocumentRep document={document} />}
        </Box>
    )
}

export default DocLink