export const openDatabase = (dbDef: { store: string, version: number }[]): Promise<IDBDatabase> => {
    return new Promise((resolve, reject) => {
        const version = dbDef.reduce((acc, def) => Math.max(acc, def.version), 1);
        const request = indexedDB.open('Evidence 101', version);

        request.onupgradeneeded = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            const oldVersion = event.oldVersion;

            if (oldVersion === 0) {
                db.createObjectStore('tokens', { keyPath: 'id' });
            }
            // const newVersion = event.newVersion || db.version;
            // console.log(`Upgrading database from version ${oldVersion} to ${newVersion}`);

            // dbDef.forEach((def) => {
            //     if (oldVersion < def.version) {
            //         db.createObjectStore(def.store, { keyPath: 'id' });
            //     }
            // });
        };

        request.onsuccess = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            console.log('Database opened')
            resolve(db);
        };

        request.onerror = async (event) => {
            await indexedDB.deleteDatabase('Evidence 101');
            window.location.reload();
            reject((event.target as IDBOpenDBRequest).error);
        };
    });
};

export const get = async <T>(db: IDBDatabase | null, storeName: string, key: string): Promise<T | null> => {
    if (!db) return null;
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readonly');
        const store = transaction.objectStore(storeName);
        const request = store.get(key);
        request.onsuccess = () => {
            resolve(request.result);
        };
        request.onerror = () => {
            reject(request.error);
        };
    });
}

export const patch = async <T>(db: IDBDatabase | null, storeName: string, value: T): Promise<void> => {
    if (!db) return;
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readwrite');
        const store = transaction.objectStore(storeName);
        const request = store.put(value);
        request.onsuccess = () => {
            resolve();
        };
        request.onerror = () => {
            reject(request.error);
        };
    });
}

export const clearStore = async (db: IDBDatabase | null, storeName: string): Promise<void> => {
    if (!db) return;
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readwrite');
        const store = transaction.objectStore(storeName);
        const request = store.clear();
        request.onsuccess = () => {
            resolve();
        };
        request.onerror = () => {
            reject(request.error);
        };
    });
} 