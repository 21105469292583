import { WarningAmber } from "@mui/icons-material";

export const AlertConfig = {
    type: 9,
    label: 'Alerte',
    icon: WarningAmber,
    allowedBy: [2],
    autoConfig: true,
    defaultProps: {
        severity: 'primary',
        icon: false,
        variant: 'outlined'
    }
}