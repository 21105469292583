import React from 'react'

import { Box, IconButton } from '@mui/material'
import { Fullscreen, FullscreenExit } from '@mui/icons-material'

import { SlideShowContext } from '.'

export const ButtonFullScreen = () => {
    const { fullScreen, dispatch } = React.useContext(SlideShowContext)
    return (
        <Box position={'absolute'} top={0} right={0} zIndex={fullScreen ? 9999 : 500}>
            <IconButton onClick={dispatch.toggleFullScreen}>
                {fullScreen ? <FullscreenExit /> : <Fullscreen />}
            </IconButton>
        </Box>
    )
}
