import type { SxProps, Theme } from "@mui/material"

export const defaultSx: SxProps<Theme> = {
    position: 'relative',
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    aspectRatio: '16/9',
    '&.fullScreen': {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100vh',
        width: '100vw',
        zIndex: '2200 !important',
        '& video': {
            width: '100%',
            height: '100%',
        }
    },
    '& .helper': {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        height: '50%',
        color: 'white'
    },
    '& video': {
        width: '100%',
        height: '100%'
    },
    '&.hideControls': {
        '& .controls': {
            height: '0px !important',
            '& .buttons': {
                visibility: 'hidden'
            },
            '& .progress': {
                visibility: 'visible !important',
            },
        }
    },
    '& .controls': {
        backgroundColor: 'rgba(0,0,0,0.8)',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        '& .buttons': {
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            alignItems: 'center',
            color: 'white',
            '& button': {
                color: 'white'
            },
        },
        '& .progress': {
            visibility: 'visible !important',
            position: 'absolute',
            top: '-4px',
            left: 0, right: '0',
            '&:hover': {
                top: '-8px',
                height: '8px',
                '& span': { height: '8px' }
            }
        }
    },
}