import { Avatar, Container, Stack, Typography } from '@mui/material'

import { BreadCrumb, usePage } from '..'

export const Header = () => {
    const { props: { title, subtitle, imageId, imageAsset, imageVariant, rightPlace, breadcrumb, container } } = usePage()
    return (
        <Stack direction={'column'} gap={1} p={1} sx={{
            backgroundColor: theme => theme.palette.background.paper
        }}>
            <Stack direction={'row'} gap={2} alignItems={'center'} component={container ? Container : 'div'}>
                {(imageId || imageAsset) &&
                    <Stack>
                        <Avatar
                            sx={breadcrumb && subtitle
                                ? { width: 85, height: 85 }
                                : (breadcrumb || subtitle)
                                    ? { width: 60, height: 60 }
                                    : {}}
                            src={imageId ? `/download/${imageId}` : imageAsset}
                            variant={imageVariant}
                        />
                    </Stack>}
                <Stack flexGrow={1}>
                    {typeof title === 'string'
                        ? <Typography variant={'h5'} component={'h2'}>{title}</Typography>
                        : title}
                    {typeof subtitle === 'string'
                        ? <Typography variant={'subtitle2'} component={'h3'} >{subtitle}</Typography>
                        : subtitle}
                    <BreadCrumb />
                </Stack>
                <Stack>
                    {rightPlace}
                </Stack>
            </Stack>
        </Stack>
    )
}