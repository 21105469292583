import type { SxProps, Theme } from "@mui/material";
import { Box, CircularProgress, LinearProgress, Typography } from "@mui/material";


interface LoaderProps {
    primary?: string,
    secondary?: string,
    progress?: 'linear' | 'circular'
    sx?: SxProps<Theme>
}
export const Loader = ({ primary, secondary, progress = 'circular', sx = {} }: LoaderProps) => {
    return (
        <Box display={'flex'} flexGrow={1} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap={2} sx={sx}>
            <Box textAlign={'center'}>
                {primary && <Typography variant="h6" >{primary}</Typography>}
                {secondary && <Typography variant="caption" gutterBottom>{secondary}</Typography>}
                {progress === 'linear' && <LinearProgress sx={{ mt: 2 }} />}
            </Box>
            {progress === 'circular' && <CircularProgress />}
        </Box>
    )
}
