import React from 'react'

import { Box } from '@mui/material'

import { SlideShowContext, SxDefinition } from '.'

export const MainContainer = ({ children }: React.PropsWithChildren) => {
    const { SliderRef } = React.useContext(SlideShowContext)
    return (
        <Box
            ref={SliderRef}
            sx={(theme) => SxDefinition(theme) as any}
        >
            {children}
        </Box>
    )
}