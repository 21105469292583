import { Stack, Divider, Container } from '@mui/material'

import { usePage } from '..'
import { Header, TabsManager } from '.'
import React from 'react'
import { Loader } from '../..'

export const PageUi = () => {
    const { props: { children, fullScreen, gap, container, noMargin, loading } } = usePage()
    return (
        <Stack
            direction={'column'}
            sx={{
                position: 'relative',
                height: '100%',
                overflowY: 'hidden',
            }}>
            <Header />
            {loading === true
                ? <Loader />
                : <React.Fragment>
                    <TabsManager />
                    {children && <Divider />}
                    {children &&
                        <Stack flexGrow={1} sx={{
                            position: 'relative',
                            height: '100%',
                            ...(!fullScreen ? { overflowY: 'auto' } : {}),
                            gap,
                            mt: -0.1,
                            mb: -0.1,
                            pt: noMargin ? 0 : 1,
                            pb: noMargin ? 0 : 1
                        }}>
                            <Stack
                                component={container ? Container : 'div'}
                                flexGrow={1}
                                gap={gap}
                                sx={{ poisition: 'relative', height: '100%', overflowY: 'hidden' }}
                            >
                                {children}
                            </Stack>
                        </Stack>}
                </React.Fragment>}

        </Stack>
    )
}