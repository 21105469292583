import { Link } from "react-router-dom";

import { Box, Card, CardActionArea, CardMedia, SxProps, Theme, Typography } from "@mui/material";

import { DocumentLogo, ProgressionRep, UserServiceProgress } from "../..";
import { AuthorsList, DocumentLikeButton, SlidePicture } from ".";

interface DocumentInterface {
    id: string
    type: number & (1 | 2 | 3 | 4 | 5 | 6)
    name: string
    [key: string]: any;
}

interface DocumentRepProps {
    document: DocumentInterface
    sx?: SxProps<Theme>,
    logoSx?: SxProps,
    onClick?: () => void
    docTypeTooltip?: boolean
    likeButton?: boolean,
    progression?: number
    userProgression?: number
    serviceProgression?: number
    progressVariant?: 'linear' | 'circular'
}
export const DocumentRep = ({ document, sx = {}, logoSx = {}, onClick = undefined, docTypeTooltip = undefined, likeButton = true, progression, serviceProgression, userProgression, progressVariant = 'linear' }: DocumentRepProps) => {
    return (
        <Card sx={sx}>
            <CardActionArea
                onClick={onClick ?? undefined}
                component={onClick ? 'button' : Link} to={`/documents/${document.type}/${document.id}`}
                sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: 2,
                    alignItems: 'stretch'
                }}>
                {document.type === 2 || document.type === 5
                    ? <SlidePicture documentName={document.name} />
                    : document.type === 3 && document.journal?.imageId
                        ? <CardMedia image={`/download/${document.journal?.imageId}`} sx={{
                            minWidth: { xs: '100%', md: 170 * 1.56 },
                            minHeight: { xs: 200, md: 150 },
                            backgroundSize: 'contain',
                        }} />
                        : document.imageId && <CardMedia image={`/download/${document.imageId}`} sx={{
                            minWidth: { xs: '100%', md: 170 * 1.56 },
                            minHeight: { xs: 200, md: 150 },
                            objectFit: 'contain',
                        }} />
                }

                <Box display={{ xs: 'none', md: 'flex', alignItems: 'center' }}><DocumentLogo type={document.type} sx={{ fontSize: 60, ...logoSx }} tooltip={docTypeTooltip} /></Box>
                <Box display={'flex'} flexDirection={'column'} flexGrow={1} textAlign={{ xs: 'center', md: 'left' }} alignItems={{ xs: 'center', md: 'flex-start' }} justifyContent={'center'} p={theme => theme.spacing(2)}>
                    <Typography variant="h5">{document.name}</Typography>
                    <Typography variant="caption" color="GrayText">{document.description}</Typography>
                </Box>
                {progressVariant === 'circular' && (userProgression && serviceProgression) ? <Box display={'flex'} alignItems={'center'}>
                    <UserServiceProgress user={userProgression ?? progression ?? undefined} service={serviceProgression ?? undefined} />
                </Box> : <></>}
                <Box display={'flex'} gap={2} flexWrap="nowrap" alignItems={'center'} justifyContent={'center'} mr={{ xs: 0, md: 2 }}>
                    <Box display={{ xs: 'flex', md: 'none' }} alignItems={'center'}><DocumentLogo type={document.type} /></Box>
                    {document.contacts && document.contacts.length > 0
                        ? <AuthorsList contacts={document.contacts.map((c: any) => c.contact)} anchorOrigin={{ vertical: 'center', horizontal: 'left' }} transformOrigin={{ vertical: 'center', horizontal: 'right' }} />
                        : <></>}
                    {likeButton &&
                        <Box>
                            <DocumentLikeButton documentId={document.id} size="medium" />
                        </Box>}
                    {progressVariant === 'linear' && <ProgressionRep user={userProgression ?? progression ?? undefined} service={serviceProgression} />}
                </Box>
            </CardActionArea>
        </Card>
    )
}