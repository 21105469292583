import { Box } from '@mui/material'

import { Progress, PlayPauseButton, NextPrevButtons, SoundControls, Duration, SourceMenu, SpeedMenu, FullScreenButton, AutoPlay } from '.'

export const BottomContainer = () => {
    return (
        <Box className="controls">
            <Progress />
            <Box className="buttons">
                <PlayPauseButton />
                <NextPrevButtons />
                <SoundControls />
                <Duration />
                <Box sx={{ ml: 'auto' }} />
                <AutoPlay />
                <SourceMenu />
                <SpeedMenu />
                <FullScreenButton />
            </Box>
        </Box>
    )
}