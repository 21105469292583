import { Grid } from '@mui/material'
import { ComponentWithElementProps } from '../types'
import ElementSwitcher from './ElementSwitcher'


const GridItem = ({ element }: ComponentWithElementProps) => {
    return (
        <Grid item {...element.props} >
            {element.childs.map(child => <ElementSwitcher key={child.id} element={child} />)}
        </Grid>
    )
}

export default GridItem