import type { LocaleType } from "../AppProvider"

import React from "react"

import { Box, Select, MenuItem, ListItemAvatar, Avatar, ListItemText } from "@mui/material"

import { useLangage } from "../AppProvider"


interface ThematicLike {
    id: string,
    name: Record<LocaleType, string>
    imageId: string | null
}
interface ThematicSelectorProps<T extends ThematicLike> {
    thematics: T[],
    selectedThematic: T,
    onSelect: (thematic: T) => void
    label?: string
}

export const ThematicSelectorWS = <T extends ThematicLike>({ thematics, selectedThematic, onSelect, label = undefined }: ThematicSelectorProps<T>) => {
    const { locale } = useLangage()
    const handleSelect = (id: ThematicLike['id']) => {
        if (!thematics) return
        onSelect([...thematics].find((t: ThematicLike) => t.id === id)!)
    }
    return (
        <Box>
            <Select
                label={label}
                value={selectedThematic!.id}
                onChange={({ target: { value } }: any) => handleSelect(value)}
                fullWidth

                renderValue={(value: ThematicLike['id']) => <MenuItem key={value} value={value} sx={{ display: 'flex' }}>
                    <ListItemAvatar>
                        <Avatar src={`/download/${selectedThematic!.imageId}`} variant="rounded" />
                    </ListItemAvatar>
                    <ListItemText
                        primary={selectedThematic!.name[locale]} />
                </MenuItem>}
            >
                {thematics?.map(thematic =>
                    <MenuItem key={thematic.id} value={thematic.id} sx={{ display: 'flex' }}>
                        <ListItemAvatar>
                            <Avatar src={`/download/${thematic.imageId}`} variant="rounded" />
                        </ListItemAvatar>
                        <ListItemText
                            primary={thematic.name[locale]} />
                    </MenuItem>)}
            </Select>

        </Box>
    )
}