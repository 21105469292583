import { styled } from "@mui/material";



export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth' })<{
    open?: boolean
    drawerWidth: number,
}>(({ theme, open, drawerWidth }) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: '-100%',
    [theme.breakpoints.up('md')]: {
        marginLeft: `-${drawerWidth}px`,
    },
    ...(open && {
        [theme.breakpoints.up('md')]: {
            marginLeft: `calc(100% -${drawerWidth}px)`,
        },
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

