
import { IconButton } from "@mui/material";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { useClient, useUser } from "../../../AppProvider";

interface DocumentLikeButtonProps {
    documentId: string
    size?: "small" | "medium"
}
export const DocumentLikeButton = ({ documentId, size }: DocumentLikeButtonProps) => {
    const user = useUser()
    const { sendMessage } = useClient()

    if (!user) return null

    const isLike: boolean = user!.favorites.map((d: any) => d.id).includes(documentId)

    const handleToggleLike = (event: any) => {
        event.preventDefault()
        event.stopPropagation()
        sendMessage('ConnectedUser', 'toggleFavorites', { action: isLike ? 'remove' : 'add' }, documentId)
    }


    return (
        <IconButton color={isLike ? 'secondary' : 'primary'} size={size} onClick={handleToggleLike}>
            {isLike
                ? <Favorite fontSize={size} />
                : <FavoriteBorder fontSize={size} />}
        </IconButton>
    )
}