import React from 'react'

import { Box } from '@mui/material'

import { defaultSx, VideoPlayerContext } from '.'

export const MainContainer = ({ children }: React.PropsWithChildren) => {
    const { refPlayer, refVideo, source, autoplay } = React.useContext(VideoPlayerContext)
    return (
        <Box ref={refPlayer} sx={defaultSx} className={"hideControls"} >
            <video ref={refVideo} controls={false} src={`/download/${source.fileId}`} autoPlay={autoplay} />
            {children}
        </Box>
    )
}