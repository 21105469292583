import React from "react"
import light from './light'
import { Theme } from "@mui/material"

type ThemeContextType = {
    theme: Theme,
    themeName: string,
    toggleTheme: () => void
}

const ThemeContext = React.createContext<ThemeContextType>({
    theme: light,
    themeName: light.palette.mode,
    toggleTheme: () => { }
})

export default ThemeContext