import { Chip, Container, Divider, Stack, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import { usePage } from '..'

export const TabsManager = () => {
    const { props: { container, fullScreen, gap, tabs }, tab, setTab } = usePage()

    if (!tabs || !tab) return null

    return (
        <TabContext value={tab}>
            <Stack
                sx={{
                    backgroundColor: theme => theme.palette.background.paper
                }}>
                <Divider />
                <Stack
                    component={container ? Container : 'div'}
                >
                    <TabList
                        onChange={(_, value) => setTab(value)}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {tabs.list.map(({ name, key, Icon, badge, iconPosition = 'start', disabled }) => (
                            <Tab
                                icon={badge ? <Chip label={badge} size='small' sx={{ ml: 1, mt: -.4 }} /> : Icon ? <Icon /> : undefined}
                                iconPosition={badge ? 'end' : iconPosition}
                                key={key ?? name}
                                label={name}
                                value={key ?? name}
                                disabled={disabled} />
                        ))}
                    </TabList>
                </Stack>
                <Divider />
            </Stack>
            {tabs.list.map(({ name, Element, key }) => (
                <TabPanel
                    key={key ?? name}
                    value={key ?? name}
                    sx={{
                        flexGrow: 1, maxHeight: '100%', p: 0, m: 0, pt: 1,
                        ...(!fullScreen ? { overflowY: 'auto' } : {}),
                    }}
                >
                    <Stack
                        flexGrow={1}
                        component={container ? Container : 'div'}
                        sx={{
                            gap,
                            height: '100%'
                        }}>
                        <Element />
                    </Stack>
                </TabPanel>
            ))}
        </TabContext >
    )
}