import React from 'react'
import { Table as MuiTable, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material'
import { ComponentWithElementProps } from '../types'

const Table = ({ element }: ComponentWithElementProps) => {
    return (
        <MuiTable>
            {element.hasHeader && <TableHead>
                <TableRow>
                    {element.column?.map(col => <TableCell key={col.id}>{col.value}</TableCell>)}
                </TableRow>
            </TableHead>}
            {element.hasFooter && <TableFooter>
                {element.column?.map(col => <TableCell key={col.id}>{col.value}</TableCell>)}
            </TableFooter>}
            <TableBody>
                {element.tableSets?.map(set =>
                    <TableRow key={set.id}>
                        {element.column?.map(({ id }) => <TableCell key={`${set.id}-${id}`}>
                            {set[id] ?? ''}
                        </TableCell>)}
                    </TableRow>)}
            </TableBody>
        </MuiTable>
    )
}

export default Table