import React from 'react'

import { Box, IconButton, Collapse, Slider } from '@mui/material'
import { VolumeOff, VolumeUp } from '@mui/icons-material'

import { VideoPlayerContext } from '..'

export const SoundControls = () => {
    const { refSound, sound, hoverSound, dispatch } = React.useContext(VideoPlayerContext)
    return (
        < Box ref={refSound} display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'} >
            <IconButton onClick={() => dispatch.changeSound(sound === 0 ? 50 : 0)}>
                {sound === 0 ? <VolumeOff /> : <VolumeUp />}
            </IconButton>
            <Collapse in={hoverSound} orientation={'horizontal'} >
                <Box sx={{ width: '100px', pl: 1, mb: -1 }}>
                    <Slider onChange={(_, value) => dispatch.changeSound(value as number)} min={0} max={100} value={sound} />
                </Box>
            </Collapse>
        </Box >
    )
}