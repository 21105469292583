import React from 'react'
import { Typography as MuiTypo } from '@mui/material'
import { ComponentWithElementProps } from '../types'

const Typography = ({ element }: ComponentWithElementProps) => {
    return (
        <MuiTypo {...element.props}>{element.value}</MuiTypo>
    )
}

export default Typography