import { TextField, TextFieldProps, alpha, styled } from '@mui/material'
import React from 'react';

const InputField = styled((props: TextFieldProps) => (
    <TextField
        variant="filled"
        {...props}
    />
))(({ theme }) => ({
    '& .MuiFilledInput-root': {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 10,
        backgroundColor: theme.palette.background.default,
        userSelect: 'unset',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&::before': {
            borderBottom: 'unset !important'
        },
        '&:hover': {
            backgroundColor: theme.palette.background.default,
        },
        '&.Mui-focused': {
            backgroundColor: theme.palette.background.default,
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

interface ControledFieldProps<T> {
    value: T
    onSave: (value: T) => void
}
export const ControledField = ({
    value,
    onSave,
    ...rest
}: ControledFieldProps<any> & TextFieldProps) => {
    const [v, setV] = React.useState<any>('')

    const handleChange = () => {
        if (v !== value) onSave(v)
    }

    const onChange = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
        setV(value)
        if (rest.select || ['date'].includes(rest.type ?? '')) onSave(value)
    }

    React.useEffect(() => {
        if (v !== value) setV(value)
    }, [value])
    return (
        <InputField
            {...rest}
            value={v}
            onChange={onChange}
            onBlur={handleChange}
            onKeyUp={({ key }) => key === 'Enter' && handleChange()}
        />
    )
}

export default InputField
