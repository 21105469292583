import React from 'react'

import { Box, Button } from '@mui/material'
import { ChevronLeft } from '@mui/icons-material'

import { SlideShowContext } from '.'

interface NavButtonProps {
    position: 'left' | 'right'
}
export const NavButton = ({ position }: NavButtonProps) => {
    const { activeIndex, slides, dispatch } = React.useContext(SlideShowContext)
    return (
        <Box role={`button-${position}`} >
            <Button
                disabled={position === 'left' ? activeIndex === 0 : slides.length - 1 === activeIndex}
                onClick={() => position === 'left' ? dispatch.goPrev() : dispatch.goNext()}
            >
                <ChevronLeft />
            </Button>
        </Box>
    )
}