import React from 'react'

import { Button, Menu, ListSubheader, ListItemAvatar, ListItemText, ListItemButton, ListItemSecondaryAction } from '@mui/material'
import { ArrowDropDown, ArrowDropUp, Check } from '@mui/icons-material'

import { VideoPlayerContext } from '..'

import { Avatar169, CircularProgressWithLabel } from '../..'

export const SourceMenu = () => {
    const { source, timeline, sourceIndex, sources, anchorVideoMenu, progressions, current, dispatch } = React.useContext(VideoPlayerContext)

    const isInTimelineChapitre = (index: number, position: number) => {
        if (!timeline || !current || !sources) return false

        const resp = timeline.filter((t, i, s) =>
            (t.index === sourceIndex && t.position <= current) ||
            (t.index + 1 === sourceIndex && s[i + 1]?.position > current)
            // (s[i + 1]?.index + 1 === sourceIndex && s[i + 1]?.position > current)
        ).at(-1)

        return resp?.index === index && resp?.position === position
    }

    if (timeline) return (
        <React.Fragment>
            <Button onClick={({ currentTarget }) => dispatch.toggleVideoMenu(currentTarget)} endIcon={Boolean(anchorVideoMenu) ? <ArrowDropUp /> : <ArrowDropDown />}>
                Chapitres
            </Button>
            <Menu
                open={Boolean(anchorVideoMenu)}
                anchorEl={anchorVideoMenu}
                onClose={() => dispatch.toggleVideoMenu(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <ListSubheader>Chapitres disponibles</ListSubheader>
                {timeline.map((source, index) =>
                    <ListItemButton
                        key={index}
                        selected={isInTimelineChapitre(source.index, source.position)}
                        onClick={() => dispatch.goChapter(source.index, source.position)}>
                        <ListItemAvatar>
                            <Avatar169 src={`/download/${source.fileId}/thumbnail`} />
                        </ListItemAvatar>
                        <ListItemText primary={source.name || `Chapitre ${index + 1}`} sx={{ ml: 2, mr: 8 }} />
                    </ListItemButton>)}
            </Menu>
        </React.Fragment>
    )
    if (!sources || sources.length === 1) return <></>
    return (
        <React.Fragment>
            <Button onClick={({ currentTarget }) => dispatch.toggleVideoMenu(currentTarget)} endIcon={Boolean(anchorVideoMenu) ? <ArrowDropUp /> : <ArrowDropDown />}>
                {source.title}
                {!source.title && `Vidéo ${sourceIndex + 1}/${sources.length}`}
            </Button>
            <Menu
                open={Boolean(anchorVideoMenu)}
                anchorEl={anchorVideoMenu}
                onClose={() => dispatch.toggleVideoMenu(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <ListSubheader>Vidéos disponibles</ListSubheader>
                {sources.map((source, index) =>
                    <ListItemButton
                        key={index}
                        selected={index === sourceIndex}
                        onClick={() => dispatch.changeSource(index)}>
                        <ListItemAvatar>
                            <Avatar169 src={`/download/${source.fileId}/thumbnail`} />
                        </ListItemAvatar>
                        <ListItemText primary={source.title || `Vidéo ${index + 1}`} sx={{ ml: 2, mr: 8 }} />
                        <ListItemSecondaryAction sx={{ mt: .5 }}>
                            {progressions.find(p => p.slideId === source.id)?.videoProgress === 1
                                ? <Check color={'primary'} />
                                : <CircularProgressWithLabel variant='determinate' value={(progressions.find(p => p.slideId === source.id)?.videoProgress ?? 0) * 100} thickness={5} color='secondary' />}
                        </ListItemSecondaryAction>
                    </ListItemButton>)}
            </Menu>
        </React.Fragment>
    )
}