import React from 'react'
import { Link } from 'react-router-dom'

import { Avatar, Box, Breadcrumbs, Chip, emphasize, styled } from '@mui/material'

import { usePage } from '..'
import { useLangage, useRoutage } from '../../../AppProvider'

export const BreadCrumb = () => {
    const { props: { breadcrumb: hasBreadcrumb } } = usePage()
    const { routes, location } = useRoutage()
    const { trad } = useLangage()

    const breadcrumbs = React.useMemo(() => {
        const pathnames = location.pathname.split('/').filter(x => x);
        let brRoutes: any = []
        let paths = ['/', ...pathnames]
        let localUrl = ''

        const findRoute = (url: string) => routes.find(route => typeof route !== 'string' && route.url === url)

        for (let i = 0; i < paths.length; i++) {
            localUrl += i > 1 ? '/' + paths[i] : paths[i]
            const route = findRoute(localUrl)
            if (route && route !== 'sep') {
                if (route.trad === undefined) console.warn('No trad for route', route)
                brRoutes.push({
                    url: route.url,
                    name: ((route.trad ?? '') in trad) ? trad[route.trad as string] : route.trad,
                    icon: route.icon
                })
            }
        }

        return brRoutes
    }, [location.pathname])

    if (!hasBreadcrumb || !breadcrumbs || breadcrumbs.length === 0) return (<></>)
    return (
        <Box ml={-.5} mt={2}>
            <Breadcrumbs separator={'›'}>
                {breadcrumbs.map((br: any, index: number) =>
                    <StyledBreadcrumb
                        key={br.url ?? index}
                        component={Link}
                        to={br.url ?? '#'}
                        label={br.name}
                        icon={br.icon ? <br.icon fontSize="small" /> : br.src ? <Avatar src={br.src} sx={{ width: 20, height: 20 }} /> : undefined}
                        avatar={!br.icon && !br.src && br.letters ? <Avatar>{br.letters}</Avatar> : undefined}
                    />)}
            </Breadcrumbs>
        </Box>
    )
}

export const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
}) as typeof Chip;
