import React from 'react'

import { Box, LinearProgress } from '@mui/material'

import { VideoPlayerContext } from '..'

export const Progress = () => {
    const { refProgress, duration, current, dispatch } = React.useContext(VideoPlayerContext)
    return (
        <Box className="progress">
            <LinearProgress ref={refProgress} onClick={dispatch.setCurrent} variant={'determinate'} value={(duration && ((current ?? 0) / duration) * 100) ?? 0} />
        </Box>
    )
}