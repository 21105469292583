import type { SxProps, Theme } from '@mui/material'

import image from '../../../assets/banniere.png'
import { Box } from '@mui/material'

interface SlidePictureProps {
    documentName: string
    isSlide?: boolean
    thumbSx?: SxProps<Theme>
}
export const SlidePicture = ({ documentName, isSlide, thumbSx }: SlidePictureProps) => {
    return (
        <Box sx={{
            position: 'relative',
            width: isSlide ? '100%' : { xs: '100%', md: `268.2px` },
            height: isSlide ? '100%' : { xs: 'unset', md: 150 },
            // aspectRatio: '16/9',
            display: 'flex',
            alignItems: 'center', justifyContent: 'center',
            '& div.title': {
                position: 'absolute',
                display: 'flex',
                alignItems: 'center', justifyContent: 'center',
                height: '75%',
                top: 0, left: 0, right: 0,
                color: 'white',
                // fontWeight: { xs: 400, lg: 800 },
                fontSize: isSlide
                    ? { xs: 30, sm: 60, md: 80 }
                    : { xs: 25, sm: 40, md: 15 },
                p: isSlide
                    ? { xs: 1, md: 4, lg: 2 }
                    : { xs: 1, md: 4, lg: 2 },
                '& div': { textAlign: 'center' }
            },
            '& img': {
                width: isSlide ? '100%' : { xs: '100%', md: `268.2px` },
                height: '100%',
                objectFit: 'cover',
            },
            ...thumbSx
        }}>
            <img src={image} alt="" />
            <div className="title">
                <div>
                    {documentName}
                </div>
            </div>
        </Box>
    )
}
