import { createTheme, ThemeOptions } from "@mui/material";

const definition: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: "#00b3bd",
            light: "rgb(101, 115, 195)",
            dark: "rgb(44, 56, 126)",
            contrastText: "#fff"
        },
        secondary: {
            main: "#f50057",
            light: "rgb(247, 51, 120)",
            dark: "rgb(171, 0, 60)",
            contrastText: "#fff"
        },
        error: {
            light: "#e57373",
            main: "#f44336",
            dark: "#d32f2f",
            contrastText: "#fff"
        },
        warning: {
            light: "#ffb74d",
            main: "#ff9800",
            dark: "#f57c00",
            contrastText: "rgba(0, 0, 0, 0.87)"
        },
        info: {
            light: "#64b5f6",
            main: "#2196f3",
            dark: "#1976d2",
            contrastText: "#fff"
        },
        success: {
            light: "#81c784",
            main: "#4caf50",
            dark: "#388e3c",
            contrastText: "rgba(0, 0, 0, 0.87)"
        },
        grey: {
            50: "#fafafa",
            100: "#f5f5f5",
            200: "#eeeeee",
            300: "#e0e0e0",
            400: "#bdbdbd",
            500: "#9e9e9e",
            600: "#757575",
            700: "#616161",
            800: "#424242",
            900: "#212121",
            A100: "#d5d5d5",
            A200: "#aaaaaa",
            A400: "#303030",
            A700: "#616161"
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.54)",
            disabled: "rgba(0, 0, 0, 0.38)",
        },
        divider: "rgba(0, 0, 0, 0.12)",
        background: {
            paper: "#fff",
            default: "#fafafa"
        },
        action: {
            active: "rgba(0, 0, 0, 0.54)",
            hover: "rgba(0, 0, 0, 0.04)",
            hoverOpacity: 0.04,
            selected: "rgba(0, 0, 0, 0.08)",
            selectedOpacity: 0.08,
            disabled: "rgba(0, 0, 0, 0.26)",
            disabledBackground: "rgba(0, 0, 0, 0.12)",
            disabledOpacity: 0.38,
            focus: "rgba(0, 0, 0, 0.12)",
            focusOpacity: 0.12,
            activatedOpacity: 0.12
        },
    },
    mixins: {
        toolbar: {
            minHeight: 56,
            "@media (min-width:0px) and (orientation: landscape)": {
                minHeight: 48
            },
            "@media (min-width:600px)": {
                minHeight: 64
            }
        }
    },

    typography: {
        fontFamily: 'Hind Madurai',
    },
    components: {
        // MuiCssBaseline: {
        //     styleOverrides: `
        //         @font-face {
        //             font-family: 'Milliard Thin';
        //             font-style: normal;
        //             font-weight: normal;
        //             src: local('Milliard Thin'), url(${MilliardThinFont}) format('woff');
        //         }`,
        // },
    },
};
const darkTheme = createTheme(definition)

export default darkTheme