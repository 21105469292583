import React from 'react'
import { Alert as MuiAlert, AlertTitle } from '@mui/material'
import { ComponentWithElementProps } from '../types'
import ElementSwitcher from './ElementSwitcher'

const Alert = ({ element }: ComponentWithElementProps) => {
    return (
        <MuiAlert {...element.props}>
            {element.props.title && <AlertTitle>{element.props.title}</AlertTitle>}
            {element.childs.map(child => <ElementSwitcher key={child.id} element={child} />)}
        </MuiAlert>

    )
}

export default Alert