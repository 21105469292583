import { ViewCarousel } from "@mui/icons-material";

export const CarouselConfig = {
    type: 10,
    label: 'Carousel',
    icon: ViewCarousel,
    allowedBy: [2],
    autoConfig: true,
    defaultProps: {
        stepperVariant: 'dots',
        autoPlay: false,
    }
}