import React from 'react'
import { VideoPlayerContext } from '../useVideoPlayer'
import { Box, Typography, LinearProgress } from '@mui/material'

export const PreloadScreen = () => {
    const { status } = React.useContext(VideoPlayerContext)
    if (status !== undefined) return <></>
    return (
        <Box className="helper">
            <Typography variant={'h6'}>Chargement...</Typography>
            <LinearProgress sx={{ minWidth: '50%' }} />
        </Box>
    )
}
