import { PlaylistAddCheck } from "@mui/icons-material";

export const ChecklistConfig = {
    type: 11,
    label: 'Check-liste',
    icon: PlaylistAddCheck,
    allowedBy: [2],
    autoConfig: true,
}

export const ChecklistItemConfig = {
    type: 13,
    label: 'Elément de check-liste',
    icon: PlaylistAddCheck,
    allowedBy: [11],
    autoConfig: true,
}