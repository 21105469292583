import type { useSlideShowInitialValueType } from './types'

import { MainContainer, NavButton, SlideContainer, ButtonFullScreen, SlidesAccess, SlideShowContext, useSlideShow } from '.'

export const SlideShow = (props: useSlideShowInitialValueType) => {
    const context = useSlideShow(props)
    return (
        <SlideShowContext.Provider value={context}>
            <MainContainer>
                <ButtonFullScreen />
                <SlideContainer />
                <NavButton position="left" />
                <NavButton position="right" />
                <SlidesAccess />
            </MainContainer>
        </SlideShowContext.Provider>
    )
}
