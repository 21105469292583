import { Box, SvgIcon, Typography } from '@mui/material'
import { ExitToApp } from '@mui/icons-material'

import { useRoutage, useSecurity, useUser } from '..'

/**
* @author Pierre Lucas
* @function Page401
**/

export const Page401 = () => {
    const user = useUser()
    const { logout } = useSecurity()
    const { navigate } = useRoutage()

    const handleLogout = () => logout()
    const handleHomeLocation = () => navigate('/')
    const handleGoBack = () => navigate(-1)

    return (
        <Box sx={theme => ({
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(2)
            }
        })}>
            <Box sx={theme => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                [theme.breakpoints.up('sm')]: {
                    alignItems: 'top',
                    flexDirection: 'row'
                }
            })}>
                <Typography variant="h1" color="primary">401</Typography>
                <Box display={'flex'} flexDirection={'column'}>
                    <Typography variant="h5" color="secondary" component="h2" gutterBottom>
                        NON AUTORISÉ
                    </Typography>
                    <Typography component="p" gutterBottom>
                        Désolé {user!.firstname} {user!.lastname} {`<${user!.email}>`} n'est pas autorisé à acceder à cette page.
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        '&:hover': {
                            color: theme => theme.palette.primary.main

                        }
                    }} onClick={handleHomeLocation}>
                        <Typography >
                            <SvgIcon>
                                <path fill="currentColor" d="M12 20C7.6 20 4 16.4 4 12S7.6 4 12 4 20 7.6 20 12 16.4 20 12 20M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M11 14H13V17H16V12H18L12 7L6 12H8V17H11V14" />
                            </SvgIcon>
                        </Typography>
                        <Typography gutterBottom>
                            Aller à la page d'accueil
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        '&:hover': {
                            color: theme => theme.palette.primary.main

                        }
                    }} onClick={handleGoBack}>
                        <Typography >
                            <SvgIcon>
                                <path fill="currentColor" d="M18,11V13H10L13.5,16.5L12.08,17.92L6.16,12L12.08,6.08L13.5,7.5L10,11H18M2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12M4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12Z" />
                                {/* <path fill="currentColor" d="M12 20C7.6 20 4 16.4 4 12S7.6 4 12 4 20 7.6 20 12 16.4 20 12 20M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M11 14H13V17H16V12H18L12 7L6 12H8V17H11V14" /> */}
                            </SvgIcon>
                        </Typography>
                        <Typography gutterBottom>
                            Revenir à la page précédente
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        '&:hover': {
                            color: theme => theme.palette.primary.main

                        }
                    }} onClick={handleLogout}>
                        <Typography >
                            <ExitToApp />
                        </Typography>
                        <Typography gutterBottom>
                            Se connecter avec un autre compte
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}