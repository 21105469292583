import React from 'react'

import { IconButton, ListItemButton, ListItemIcon, ListItemText, Menu, Tooltip } from '@mui/material'
import { useLangage } from '..'

export const LocaleSelector = () => {
    const { trad, locale, locales, changeLocale } = useLangage()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    return (
        <React.Fragment>
            <Tooltip title={trad.localeSelectorTooltip}>
                <IconButton onClick={(event) => { event.stopPropagation(); setAnchorEl(event.currentTarget) }}>
                    {locale.split('-')[1].replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))}
                </IconButton>
            </Tooltip>
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClick={() => setAnchorEl(null)}
            >
                {locales?.map(l => <ListItemButton key={l} role={l} selected={locale === l} onClick={() => changeLocale(l)}>
                    <ListItemIcon>
                        {l.split('-')[1].replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))}
                    </ListItemIcon>
                    <ListItemText primary={l} />
                </ListItemButton>)}
            </Menu>
        </React.Fragment>
    )
}