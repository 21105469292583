import React from 'react'
import { NavLink } from 'react-router-dom'

import { List, Divider, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemButton, ListItemIcon, ListItemSecondaryAction } from '@mui/material'
import { Person2Rounded, Favorite, FavoriteBorder, MenuBook } from '@mui/icons-material'

import { useLangage, useUser } from '../..'

const Common = () => {
    const { trad } = useLangage()
    const user = useUser()

    if (!user) return null
    return (
        <List dense sx={{ mb: -2 }}>
            {(user.service) &&
                <React.Fragment >
                    <Divider />
                    <ListItem>
                        {(user.service || user.managedService).hospital?.logoId && <ListItemAvatar>
                            <Avatar alt={(user.service || user.managedService).hospital.name} src={`/download/${(user.service || user.managedService).hospital.logoId}`} variant={'square'} />
                        </ListItemAvatar>}
                        <ListItemText primary={(user.service || user.managedService).name} secondary={`${(user.service || user.managedService).hospital.name}, ${(user.service || user.managedService).hospital.city}, ${(user.service || user.managedService).hospital.country}`} />
                    </ListItem>
                </React.Fragment>}
            {(user.managed && user.managed.length > 0 &&
                <React.Fragment>
                    {user.managed.map((service: any) => <React.Fragment key={service.id}>
                        <Divider />
                        <ListItemButton component={NavLink} to={`/service/${service.id}`}>
                            {service.hospital?.logoId && <ListItemAvatar>
                                <Avatar alt={service.hospital.name} src={`/download/${service.hospital.logoId}`} variant={'square'} />
                            </ListItemAvatar>}
                            <ListItemText primary={service.name} secondary={`${service.hospital.name}, ${service.hospital.city}, ${service.hospital.country}`} />
                        </ListItemButton>
                    </React.Fragment>)}
                </React.Fragment>)}
            <Divider />
            <ListItemButton component={NavLink} to={'/profil'}>
                <ListItemIcon>
                    <Person2Rounded />
                </ListItemIcon>
                <ListItemText primary={trad.profil} />
            </ListItemButton>
            <ListItemButton component={NavLink} to={'/favorites'}>
                <ListItemIcon>
                    {user.favorites.length > 0
                        ? <Favorite />
                        : <FavoriteBorder />}
                </ListItemIcon>
                <ListItemText primary={trad.favorites} />
                {user.favorites.length > 0 &&
                    <ListItemSecondaryAction>
                        <Avatar sx={{ width: '1.5em', height: '1.5em', fontSize: '75%', backgroundColor: (theme) => theme.palette.primary.main }}>{user.favorites.length}</Avatar>
                    </ListItemSecondaryAction>}
            </ListItemButton>
            {user.service && <ListItemButton component={NavLink} to={'/formations'}>
                <ListItemIcon>
                    {user.formations.length > 0
                        ? <MenuBook />
                        : <MenuBook color={'disabled'} />}
                </ListItemIcon>
                <ListItemText primary={trad.myFormations} />
                {user.formations.length > 0 &&
                    <ListItemSecondaryAction>
                        <Avatar sx={{ width: '1.5em', height: '1.5em', fontSize: '75%', backgroundColor: (theme) => theme.palette.primary.main }}>{user.formations.length}</Avatar>
                    </ListItemSecondaryAction>}
            </ListItemButton>}
            <Divider />
        </List>
    )
}

export default Common