import { Box, Card, CardActionArea, CardContent, CardMedia, SxProps, Typography } from "@mui/material"
import { LocalHospital } from "@mui/icons-material"
import { Link } from "react-router-dom"

interface ServiceLike {
    id: string,
    name: string
    hospital?: {
        name: string,
        logoId: string | null,
        city: string,
        country: string
        [k: string]: any
    } | null
}
interface ServiceRepProps<T extends ServiceLike> {
    service: T
    sx?: SxProps
    variant?: 'outlined' | 'elevation'
    onClick?: (service: T) => void
    link?: string
}
export const ServiceRep = <T extends ServiceLike>({ service, sx = {}, variant = 'elevation', onClick, link }: ServiceRepProps<T>) => {
    const handleClick = () => onClick ? onClick(service) : null
    return (
        <Card variant={variant}  >
            <CardActionArea disabled={!onClick && !link} sx={{ display: 'flex', ...sx }} onClick={handleClick} {...(link ? { component: Link, to: link } : {})}>
                {service.hospital?.logoId && <CardMedia image={`/download/${service.hospital.logoId}`} sx={{ minWidth: 150, minHeight: 100 }} />}
                <CardContent sx={{ display: 'flex', flexWrap: 'nowrap', gap: 2, alignItems: 'center', flexGrow: 1 }}  >
                    {!service.hospital?.logoId &&
                        <LocalHospital sx={{ fontSize: 60 }} />}
                    <Box flexGrow={1}>
                        <Typography variant="h5">{service.name}</Typography>
                        <Typography variant="subtitle1" gutterBottom={false}>{service.hospital?.name}</Typography>
                        <Typography variant="caption" color="GrayText" mb={0}>{service.hospital?.city} – {service.hospital?.country}</Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}