import type { returnPayloadType } from "."

import React from "react"

import { AppContext } from "."

export const useClient = (listener?: ((msg: returnPayloadType) => void)) => {
    const { sendMessage, sendFile, ws } = React.useContext(AppContext)

    React.useEffect(() => {

        const onmessage = (event: MessageEvent) => {
            const message = JSON.parse(event.data)
            if (listener) listener(message)
        }

        if (listener) {
            ws?.addEventListener('message', onmessage)
        }

        return () => {
            if (listener) ws?.removeEventListener('message', onmessage)
        }
    }, [listener, ws])

    return { sendMessage, sendFile, ws }
}
export const useLangage = () => {
    const { langage } = React.useContext(AppContext)
    return langage
}
export const useRoutage = () => {
    const { routage } = React.useContext(AppContext)
    return routage
}
export const useSearch = () => {
    const { search } = React.useContext(AppContext)
    return search
}
export const useSecurity = () => {
    const { security } = React.useContext(AppContext)
    return security
}
export const useUi = () => {
    const { ui } = React.useContext(AppContext)
    return ui
}
export const useUser = () => {
    const { user } = React.useContext(AppContext)
    return user
}