import React from 'react'

import { IconButton } from '@mui/material'
import { SkipPrevious, SkipNext } from '@mui/icons-material'

import { VideoPlayerContext } from '..'

export const NextPrevButtons = () => {
    const { sourceIndex, sources, source, dispatch } = React.useContext(VideoPlayerContext)
    return (
        <React.Fragment>
            {sourceIndex > 0 &&
                <IconButton size={'small'} onClick={dispatch.prevSource}>
                    <SkipPrevious />
                </IconButton>}
            {sources.length > 0 && sources.at(-1)!.id !== source.id &&
                <IconButton size={'small'} onClick={dispatch.nextSource}>
                    <SkipNext />
                </IconButton>}
        </React.Fragment>
    )
}
