import React from 'react'

import { Drawer, Box, Typography, Divider, Badge } from '@mui/material'

import { useSearch } from '../..'
import { SearchField } from '.'
import { DocumentRep } from '../../../commonsWS'

export const SearchDrawer = () => {
    const { results, setResults, search } = useSearch()
    const [actualSearch, setActualSearch] = React.useState('')

    React.useEffect(() => {
        setActualSearch(search)
    }, [results])
    return (
        <Drawer
            anchor={'right'}
            open={Boolean(results)}
            onClose={() => setResults(null)}
        >
            <Box sx={{ width: { xs: '100vw', md: '75vw' }, display: 'flex', flexDirection: 'column', gap: 1, p: 2, height: '100vh', overflow: 'hidden' }}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box flexGrow={1}>
                        <SearchField />
                        {results?.length
                            ? <Typography variant="subtitle1">
                                <Badge badgeContent={results?.length ?? 0} color="primary" sx={{ ml: 1.5, mr: 2 }} />
                                réultat{results?.length > 1 ? 's' : ''} pour{' '}
                                {actualSearch}
                            </Typography>
                            : <Typography variant="subtitle1">
                                Aucun réultat pour {actualSearch}
                            </Typography>}
                    </Box>
                </Box>
                <Divider />
                <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 1 }}>
                        {results?.map(document => <DocumentRep key={document.id} document={document} likeButton={false} />)}
                    </Box>
                </Box>
            </Box>

        </Drawer>
    )
}