import type { useVideoPlayerInitialType } from '.'

import { BottomContainer, MainContainer, NonPlayScreen, PreloadScreen, useVideoPlayer, VideoPlayerContext } from '.'

export const VideoPlayer = (props: useVideoPlayerInitialType) => {
    const context = useVideoPlayer(props)
    return (
        <VideoPlayerContext.Provider value={context}>
            <MainContainer>
                <PreloadScreen />
                <NonPlayScreen />
                <BottomContainer />
            </MainContainer>
        </VideoPlayerContext.Provider>
    )
}