
type ImageProps = {
    id: string
    aspect?: React.CSSProperties['aspectRatio']
    width?: React.CSSProperties['width']
}
export const Image = ({
    id,
    aspect = 'unset',
    width = '100%',
}: ImageProps) => {
    return (
        <img
            alt=""
            src={`/download/${id}`}
            style={{
                aspectRatio: aspect,
                width,
                // height: '100%',
                // objectFit: 'cover',
                // borderRadius: '5px'
            }} />
    )
}