import type { DocumentType as DocumentTypeType } from '../types/documents'

import React from 'react'

import { Avatar, Divider, ListItemAvatar, ListItemText, MenuItem, Select } from '@mui/material'
import { AutoStories } from '@mui/icons-material'

import { useLangage } from '../AppProvider'

import { convertDocumentType } from '../utils/convertDocumentType'
import { DocumentLogo } from '.'

interface DocumentTypeSelectorProps {
    documentTypes: DocumentTypeType[]
    selectType: number
    changeType: React.Dispatch<React.SetStateAction<number>>
}
export const DocumentTypeSelector = ({ documentTypes, selectType, changeType }: DocumentTypeSelectorProps) => {
    const { trad } = useLangage()
    return (
        <Select
            sx={{ width: { xs: '100%', md: `${14}em` } }}
            value={selectType}
            onChange={({ target: { value } }) => changeType(Number(value))}
            renderValue={(type) =>
                <MenuItem key={type} value={type} sx={{ display: 'flex' }}>
                    <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: 'transparent' }}>
                            {type === 0
                                ? <AutoStories color='primary' />
                                : <DocumentLogo type={type as DocumentTypeType} />}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={type === 0 ? 'Tous les types' : trad[convertDocumentType(type as any)]} />
                </MenuItem>}
            fullWidth>
            <MenuItem value={0}>
                <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: 'transparent' }} >
                        <AutoStories color='primary' />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={'Tous les types'} />
            </MenuItem>
            <Divider />
            {documentTypes.map(type => <MenuItem key={type} value={type}>
                <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: 'transparent' }} >
                        <DocumentLogo type={type} />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={trad[convertDocumentType(type)]} />
            </MenuItem>)}
            {/* <MenuItem value={''}>
                <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: 'transparent' }} >
                        <DocumentLogo type={4} />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={trad[convertDocumentType(4)]} />
            </MenuItem> */}
        </Select>
    )
}