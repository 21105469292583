import React from 'react'

import { Box, Button, IconButton } from '@mui/material'
import { PlayArrow } from '@mui/icons-material'

import { VideoPlayerContext } from '../useVideoPlayer'

export const NonPlayScreen = () => {
    const { status, dispatch, sources, sourceIndex, progressions } = React.useContext(VideoPlayerContext)
    if ((status === 'pause'))
        return (
            <Box className="helper">
                <IconButton onClick={dispatch.togglePlay} sx={{ width: '30vh', height: '30vh', border: '1px solid white', bgcolor: 'rgba(0,0,0,.7)', '&:hover': { bgcolor: 'rgba(0,0,0,.9)' } }}>
                    <PlayArrow sx={{ fontSize: "30vh", color: 'white' }} />
                </IconButton>

                {progressions.find(p => p.slideId === sources[sourceIndex].id)?.videoProgress &&
                    <Box sx={{ backgroundColor: 'white', mt: 4 }}>
                        <Button variant='outlined' color='warning' onClick={() => dispatch.setCurrentByPc(progressions.find(p => p.slideId === sources[sourceIndex].id)?.videoProgress ?? 0)}>
                            Reprendre la lecture<br />
                            à votre dernier arrêt.
                        </Button>
                    </Box>}
            </Box>
        )
    else return <></>
}
