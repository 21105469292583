import React from 'react'
import { ComponentWithElementProps } from '../types'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import ElementSwitcher from './ElementSwitcher'

const OngletContainer = ({ element }: ComponentWithElementProps) => {
    const [tab, setTab] = React.useState<string>(element.childs.length > 0 ? element.childs[0].id : '')

    const handleChangeTab = (_: any, value: string) => setTab(value)
    return (
        <TabContext value={tab}>
            <TabList onChange={handleChangeTab}>
                {element.childs.map(tab => <Tab key={tab.id} value={tab.id} label={tab.titre} />)}
            </TabList>
            {element.childs.map(tab => <TabPanel key={tab.id} value={tab.id}>
                {tab.childs.map(child => <ElementSwitcher key={child.id} element={child} />)}
            </TabPanel>)}

        </TabContext>
    )
}

export default OngletContainer