import type { useVideoPlayerContextType, useVideoPlayerDispatchType, useVideoPlayerType } from ".";
import type Slide from "../../types/slide";

import React from "react";

import { useClient, useUi } from "../../AppProvider";

const timestampToText = (timestamp: number | undefined): string => {
    if (!timestamp) return ''

    let [h, mm] = [Math.floor(timestamp / 3600), Math.floor(timestamp % 3600)]
    let [m, s] = [Math.floor(mm / 60), Math.floor(mm % 60)]

    return `${h > 0 ? `${h}:` : ''}${m < 10 ? '0' : ''}${m}:${s < 10 ? '0' : ''}${s}`
}

export const VideoPlayerContext = React.createContext<useVideoPlayerContextType>({} as useVideoPlayerContextType)

export const useVideoPlayer: useVideoPlayerType = (initial) => {
    const { sendMessage } = useClient()
    const { setZIndex } = useUi()

    const refVideo = React.useRef<HTMLVideoElement>(null)
    const refPlayer = React.useRef<HTMLDivElement>(null)
    const refSound = React.useRef<HTMLDivElement>(null)
    const refProgress = React.useRef<HTMLDivElement>(null)

    const [full, toggleFull] = React.useReducer(o => !o, false)
    const [anchorSpeedMenu, setAnchorSpeedMenu] = React.useState<null | HTMLButtonElement>(null)
    const [anchorVideoMenu, setAnchorVideoMenu] = React.useState<null | HTMLButtonElement>(null)

    const [source, setSource] = React.useState<Slide>(initial!.sources!.at(0)!)
    const [sources, setSources] = React.useState<Slide[]>(initial.sources)
    const [sourceIndex, setSourceIndex] = React.useState<number>(0)
    const [progressions] = React.useState(initial.progressions || [])

    const [duration, setDuration] = React.useState<number | undefined>()
    const [current, setCurrent] = React.useState<number | undefined>()
    const [pc, setPc] = React.useState<number | undefined>()

    const [status, setStatus] = React.useState<string | undefined>()
    const [speed, setSpeed] = React.useState<number>(1)
    const [autoplay, toggleAutoPlay] = React.useReducer(o => !o, initial.autoPlay ?? false)

    const [hoverSound, setHoverSound] = React.useState<boolean>(false)
    const [sound, setSound] = React.useState<number>(100)

    const [currentSegment, setCurrentSegment] = React.useState<{ start: number | null, end: number | null }>({ start: null, end: null })

    const dispatch: useVideoPlayerDispatchType = React.useMemo(() => ({
        toggleFullScreen: toggleFull,
        toggleSpeedMenu: setAnchorSpeedMenu,
        toggleVideoMenu: setAnchorVideoMenu,

        changeSound: (value) => setSound(value as number),
        changeSpeed: (value) => { setSpeed(value); setAnchorSpeedMenu(null) },
        changeSource: (value) => { setSourceIndex(value); setAnchorVideoMenu(null) },
        goChapter: (newSourceIndex, position) => {
            if (newSourceIndex !== sourceIndex) {
                setSourceIndex(newSourceIndex)
                refVideo.current!.onloadedmetadata = () => refVideo.current!.currentTime = position
            } else {
                refVideo.current!.currentTime = position
            }
        },

        togglePlay: () => refVideo.current?.paused ? refVideo.current?.play() : refVideo.current?.pause(),
        setCurrent: ({ clientX, currentTarget, currentTarget: { offsetWidth } }) => {
            const progressPercentage = ((clientX - refProgress.current!.getBoundingClientRect().left) / refProgress.current!.clientWidth);
            refVideo.current!.currentTime = progressPercentage * (refVideo.current?.duration ?? 0)
        },
        setCurrentByPc: (pc) => {
            refVideo.current!.currentTime = pc * (refVideo.current?.duration ?? 0)
        },
        toggleAutoPlay: toggleAutoPlay,

        nextSource: () => setSourceIndex(i => (i + 1) % sources.length),
        prevSource: () => setSourceIndex(i => (i - 1 + sources.length) % sources.length),
    }), [sourceIndex])


    React.useEffect(() => {
        if (currentSegment.start === null || currentSegment.end === null) return
        sendMessage('ConnectedUser', 'addProgression', { documentId: initial.documentId, slideId: source.id, videoRange: currentSegment })
        setCurrentSegment(prev => ({ start: prev.end, end: null }))

        console.log('currentSegment', currentSegment)
    }, [currentSegment])

    React.useEffect(() => {
        let interval: NodeJS.Timeout | null = null

        if (refVideo.current?.paused) {
            if (interval) clearInterval(interval!)
            setCurrentSegment(prev => ({ ...prev, end: refVideo.current?.currentTime ?? 0 }))
        } else {
            interval = setInterval(() => setCurrentSegment(prev => ({ ...prev, end: refVideo.current?.currentTime ?? 0 })), 5000)
            setCurrentSegment(prev => ({ start: refVideo.current?.currentTime ?? 0, end: null }))
        }

        return () => {
            if (interval) clearInterval(interval!)
            console.log('in return')
        }

    }, [refVideo.current?.paused])

    React.useEffect(() => {
        const onMouseEnter = (e: MouseEvent) => { setHoverSound(true) }
        const onMouseLeave = (e: MouseEvent) => { setHoverSound(false) }

        if (refSound.current) {
            refSound.current?.addEventListener('mouseenter', onMouseEnter)
            refSound.current?.addEventListener('mouseleave', onMouseLeave)
        }

        return () => {
            refSound.current?.removeEventListener('mouseenter', onMouseEnter)
            refSound.current?.removeEventListener('mouseleave', onMouseLeave)
        }
    }, [refSound.current])

    React.useEffect(() => {
        const onMetaData = (e: any) => {
            setDuration(e.target?.duration ?? undefined)
        }
        const onCurrentChange = (e: any) => {
            setCurrent(e.target?.currentTime ?? undefined)
        }
        const onPlay = (e: any) => {
            setStatus('play')
            setCurrentSegment(prev => ({ start: refVideo.current?.currentTime ?? 0, end: null }))
        }
        const onPause = (e: any) => {
            setStatus('pause')
            setCurrentSegment(prev => ({ ...prev, end: refVideo.current?.currentTime ?? 0 }))
        }
        const onCanPay = (e: any) => { status === undefined && setStatus('pause') }
        const onEnded = (e: any) => {
            setCurrentSegment(prev => ({ ...prev, end: refVideo.current?.currentTime ?? 0 }))
            if (autoplay) dispatch.nextSource()

        }

        const onClick = (e: MouseEvent) => { refVideo.current?.paused ? refVideo.current?.play() : refVideo.current?.pause() }
        const onDblClick = (e: MouseEvent) => { refVideo.current?.canPlayType && toggleFull() }


        if (refVideo.current) {
            refVideo.current?.addEventListener('loadedmetadata', onMetaData)
            refVideo.current?.addEventListener('play', onPlay)
            refVideo.current?.addEventListener('pause', onPause)
            refVideo.current?.addEventListener('canplay', onCanPay)
            refVideo.current?.addEventListener('timeupdate', onCurrentChange)
            refVideo.current?.addEventListener('click', onClick)
            refVideo.current?.addEventListener('dblclick', onDblClick)
            refVideo.current?.addEventListener('ended', onEnded)
        }
        return () => {
            refVideo.current?.removeEventListener('loadedmetadata', onMetaData)
            refVideo.current?.removeEventListener('play', onPlay)
            refVideo.current?.removeEventListener('pause', onPause)
            refVideo.current?.removeEventListener('canplay', onCanPay)
            refVideo.current?.removeEventListener('timeupdate', onCurrentChange)
            refVideo.current?.removeEventListener('click', onClick)
            refVideo.current?.removeEventListener('dblclick', onDblClick)
            refVideo.current?.addEventListener('ended', onEnded)

        }
    }, [refVideo.current, autoplay, setStatus, status])

    React.useEffect(() => {
        let timeout: NodeJS.Timeout | null = null
        const onMouseMove = (e: MouseEvent) => {
            if (timeout) clearTimeout(timeout)
            refPlayer.current?.classList.remove('hideControls')
            timeout = setTimeout(() => {
                refPlayer.current?.classList.add('hideControls')
            }, 3000)
        }
        if (status !== 'play') {
            refPlayer.current?.classList.remove('hideControls')
            clearTimeout(timeout!)
        } else {
            if (!timeout && !refPlayer.current?.classList.contains('hideControls')) timeout = setTimeout(() => {
                refPlayer.current?.classList.add('hideControls')
            }, 3000)
        }

        refPlayer.current?.addEventListener('mousemove', onMouseMove)

        return () => {
            refPlayer.current?.removeEventListener('mousemove', onMouseMove)
            if (timeout) clearTimeout(timeout)
        }
    }, [refPlayer.current, status])

    React.useEffect(() => {
        if (full) refPlayer.current?.classList.add('fullScreen')
        if (!full) refPlayer.current?.classList.remove('fullScreen')
        setZIndex(full ? 'unset' : 1100)

        return () => {
            setZIndex(1100)
        }
    }, [full])

    React.useEffect(() => {
        refVideo.current!.volume = sound / 100
    }, [sound])

    React.useEffect(() => {
        setSource(sources[sourceIndex])
    }, [sourceIndex, autoplay])

    React.useEffect(() => {
        refVideo.current!.playbackRate = speed
    }, [speed])

    React.useEffect(() => {
        setPc(0)
    }, [setPc, sourceIndex])

    React.useEffect(() => {
        if (current && duration && pc !== Math.floor(100 * current / duration)) setPc(Math.floor(100 * current / duration))
    }, [current, duration])

    React.useEffect(() => {
        if (refVideo.current?.paused) return
        if (sources !== initial.sources) setSources(initial.sources)
        if (pc && pc > 1) {
            // sendMessage('ConnectedUser', 'addProgression', { documentId: initial.documentId, slideId: source.id, progress: pc / 100 })
            //     .then(message => {
            //         if (message.wsCode === 200) {
            //             setProgressions(prev => prev.find(p => p.slideId === source.id)
            //                 ? prev.map(p => p.slideId === source.id ? ({ ...p, videoProgress: message.payload.videoProgress }) : p)
            //                 : [...prev, { slideId: source.id, videoProgress: message.payload.videoProgress } as any]
            //             )
            //         }
            //     })
        }
    }, [pc, sourceIndex, sources])

    React.useEffect(() => {
        if (!refVideo.current) return
        //@ts-ignore
        // refVideo.current?.textTracks[0].mode = 'showing';
    }, [refVideo.current, source, status])


    return {
        dispatch,
        fullScreen: full,

        refPlayer,
        refVideo,
        refSound,
        refProgress,
        anchorSpeedMenu,
        anchorVideoMenu,

        source,
        sources,
        sourceIndex,
        progressions,
        timeline: initial.timeline,

        speed,
        autoplay,

        hoverSound,
        sound,

        status,
        current,
        currentText: timestampToText(current),
        duration,
        durationText: timestampToText(duration)
    }
}