import { Image } from "@mui/icons-material";

export const ImageConfig = {
    type: 7,
    label: 'Image',
    icon: Image,
    allowedBy: [2],
    autoConfig: true,
    defaultProps: {
        aspectRatio: 'unset',
        objectFit: 'cover'
    }
}