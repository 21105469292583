import React from 'react'

import { IconButton } from '@mui/material'
import { FullscreenExit, Fullscreen } from '@mui/icons-material'

import { VideoPlayerContext } from '..'

export const FullScreenButton = () => {
    const { fullScreen, dispatch } = React.useContext(VideoPlayerContext)
    return (
        <IconButton onClick={dispatch.toggleFullScreen}>
            {fullScreen ? <FullscreenExit /> : <Fullscreen />}
        </IconButton>
    )
}
