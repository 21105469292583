import { Box, Typography } from "@mui/material";

import { useUi } from "../use";

import { LogoAxa } from "../../commonsWS";

export const Footer = () => {
    const { zIndex } = useUi()
    return (
        <Box
            role="footer"
            sx={{
                position: 'fixed',
                bottom: 0,
                right: 0,
                textAlign: 'center',
                padding: '0.25em',
                backgroundColor: theme => theme.palette.background.default,
                fontSize: '.75em',
                gap: 1,
                '& img': {
                    width: '4em'
                },
                display: zIndex === 1100 ? 'flex' : 'none'
            }}>
            <Typography variant="caption" component="div" sx={{ lineHeight: 'unset', color: theme => theme.palette.text.primary }}>
                Powered<br />by
            </Typography>
            <LogoAxa fontSize="large" />
        </Box>
    )
}