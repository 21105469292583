import React from 'react'

import { Box, IconButton, Menu, PopoverOrigin } from '@mui/material'
import { People } from '@mui/icons-material'

import PersonRep from '../PersonRep'

interface PersonLike {
    firstname: string
    lastname: string
    email: string
}

interface AuthorsListProps {
    contacts: PersonLike[]
    anchorOrigin?: PopoverOrigin
    transformOrigin?: PopoverOrigin
    size?: 'small' | 'medium' | 'large'
}
export const AuthorsList = ({ contacts, anchorOrigin, transformOrigin, size }: AuthorsListProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        event.preventDefault()

        setAnchorEl(Boolean(anchorEl) ? null : event.currentTarget)
    }
    return (
        <React.Fragment>
            <IconButton size={size} onClick={handleClick}>
                <People fontSize={size} />
            </IconButton>
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClick}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'stretch', justifyContent: 'stretch' }}>
                    {contacts.map(contact => <PersonRep key={`${contact.firstname} ${contact.lastname}`} person={contact} avatar />)}
                </Box>
            </Menu>
        </React.Fragment >
    )
}