import { ViewWeek } from "@mui/icons-material";

export const GridItemConfig = {
    type: 2,
    label: 'Colonne',
    icon: ViewWeek,
    allowedBy: [1],
    autoConfig: false,
    defaultProps: {
        xs: true,
        md: true,
        lg: true
    }
}