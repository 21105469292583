import { MainContainer } from './MainContainer'
import { VideoPlayer } from './VideoPlayer'
import { defaultSx } from './defaultSx'
import { VideoPlayerContext, useVideoPlayer } from './useVideoPlayer'

export * from './types'
export * from './Bottom'
export * from './Screens'

export {
    defaultSx,
    MainContainer,
    VideoPlayerContext,
    useVideoPlayer,

}
export default VideoPlayer