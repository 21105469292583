import React from 'react'

import { IconButton, Menu, ListSubheader, MenuItem } from '@mui/material'
import { Settings } from '@mui/icons-material'

import { VideoPlayerContext } from '..'

export const SpeedMenu = () => {
    const { anchorSpeedMenu, speed, dispatch } = React.useContext(VideoPlayerContext)
    return (
        <React.Fragment>
            <IconButton onClick={({ currentTarget }) => dispatch.toggleSpeedMenu(currentTarget)}>
                <Settings />
            </IconButton>
            <Menu
                open={Boolean(anchorSpeedMenu)}
                onClose={() => dispatch.toggleSpeedMenu(null)}
                anchorEl={anchorSpeedMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <ListSubheader>Vitesse de lecture</ListSubheader>
                <MenuItem selected={speed === 0.25} onClick={() => dispatch.changeSpeed(0.25)}>0.25</MenuItem>
                <MenuItem selected={speed === 0.5} onClick={() => dispatch.changeSpeed(0.5)}>0.5</MenuItem>
                <MenuItem selected={speed === 0.75} onClick={() => dispatch.changeSpeed(0.75)}>0.75</MenuItem>
                <MenuItem selected={speed === 1} onClick={() => dispatch.changeSpeed(1)}>Normale</MenuItem>
                <MenuItem selected={speed === 1.25} onClick={() => dispatch.changeSpeed(1.25)}>1.25</MenuItem>
                <MenuItem selected={speed === 1.5} onClick={() => dispatch.changeSpeed(1.5)}>1.5</MenuItem>
                <MenuItem selected={speed === 1.75} onClick={() => dispatch.changeSpeed(1.75)}>1.75</MenuItem>
                <MenuItem selected={speed === 2} onClick={() => dispatch.changeSpeed(2)}>2</MenuItem>
            </Menu>
        </React.Fragment>
    )
}
