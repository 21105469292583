import { Typography } from '@mui/material'
import React from 'react'
import CarouselEL from '../../../commons/Carousel'
import { ComponentWithElementProps } from '../types'

const Carousel = ({ element }: ComponentWithElementProps) => {
    return (
        !element.childs || element.childs.length === 0
            ? <Typography variant="caption" color="GrayText">Aucune image.</Typography>
            : <CarouselEL
                images={element.childs.map(child => child.props.src) ?? []}
                stepper={element.props.stepperVariant}
                autoPlay={element.props.autoPlay}
            />

    )
}

export default Carousel