import React from 'react'

import { Box, Typography } from '@mui/material'

import { VideoPlayerContext } from '..'

export const Duration = () => {
    const { currentText, durationText, speed } = React.useContext(VideoPlayerContext)
    return (
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Typography>{currentText && `${currentText}/`}</Typography>
            <Typography>{durationText}</Typography>
            {speed !== 1 && <Typography variant="caption" sx={{ ml: 1 }}>x{speed}</Typography>}
        </Box>
    )
}
