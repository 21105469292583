import { AuthorsList } from "./AuthorsList"
import { DocumentLikeButton } from "./DocumentLikeButton"
import { DocumentRep } from "./DocumentRep"
import { SlidePicture } from "./SlidePicture"

export {
    AuthorsList,
    DocumentLikeButton,
    SlidePicture
}



export default DocumentRep