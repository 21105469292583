import { DatasetLinked, Link } from "@mui/icons-material"

export const DocumentLinkConfig = {
    type: 15,
    label: 'Lien vers un document',
    icon: DatasetLinked,
    allowedBy: [2],
    autoConfig: true,
    defaultProps: {
    }
}


export const ExternalLinkConfig = {
    type: 16,
    label: 'Lien externe',
    icon: Link,
    allowedBy: [2],
    autoConfig: true,
    defaultProps: {
    }
}