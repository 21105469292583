import { AppBarProps, styled, AppBar } from "@mui/material";

interface HeaderProps extends AppBarProps {
    open?: boolean;
    drawerWidth: number;
}

export const Header = styled(AppBar, {
    shouldForwardProp: (props) => props !== 'open' && props !== 'drawerWidth',
})<HeaderProps>(({ theme, open, drawerWidth }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: 0,
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));