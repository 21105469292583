import type { SxProps, Theme } from "@mui/material";

export const SxDefinition = (theme: Theme): SxProps<Theme> | undefined => ({
    position: 'relative',
    width: '100%',
    aspectRatio: '16/9',
    backgroundColor: theme => theme.palette.background.paper,
    '&.fullScreen': {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        aspectRatio: 'unset',
        '& .slidesAccess': {
            top: 'unset',
            bottom: 0,
        },
        '& img': {
            padding: '2em'
        }
    },
    '& .slide-container': {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        '& [role^="slide-"]': {
            height: '100%',
            maxWidth: '100%',
            '& img': {
                height: '100%',
                maxWidth: '100%',
            }
        },
        '& [role="slide-hidden"]': {
            width: '0px',
        },

    },
    '& div[role^="button"]': {
        '& button': {
            height: '100px',
            borderRadius: 0,
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
        },
        position: 'absolute',
        top: '50%',
        bottom: '50%',
        left: 0,
        '& button:disabled': { visibility: 'hidden' },
        '&[role$="right"]': {
            "& button": {
                borderRadius: 0,
                borderTopLeftRadius: '50%',
                borderBottomLeftRadius: '50%',
            },
            right: 0,
            left: 'unset',
            '& svg': {
                transform: 'rotate(180deg)',
            }
        }
    },
    '& .slidesAccess': {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        pl: 4,
        pr: 4,
        '& [role$="active"] button': {
            backgroundColor: theme.palette!.grey[600],
        },
        '& [role^="view"] button': {
            border: `1px solid ${theme.palette.primary.main}`
        }
    }
})