import type { PageProps } from '.'

import React from 'react'

import { useUi } from '../../AppProvider'

import { PageContext, PageUi } from '.'

export const Page = ({
    fullScreen = false,
    gap = 1,
    ...props
}: PageProps) => {
    const { setOverflowY } = useUi()

    const [tab, setTab] = React.useState<string>('')

    React.useEffect(() => {
        if (!tab && props.tabs && Array.isArray(props.tabs.list)) setTab(props.tabs?.list[0].key ?? props.tabs?.list[0].name ?? '')
    }, [props.tabs])

    React.useEffect(() => {
        setOverflowY(fullScreen)
        return () => {
            setOverflowY(false)
        }
    }, [setOverflowY])
    return (
        <PageContext.Provider value={{
            props: { fullScreen, gap, ...props },
            tab, setTab
        }}>
            <PageUi />
        </PageContext.Provider >
    )
}