import { ViewWeek } from "@mui/icons-material";

export const GridContainerConfig = {
    type: 1,
    label: 'Grille',
    icon: ViewWeek,
    allowedBy: [2, 9, 18, 20],
    autoConfig: false,
    defaultProps: {
        spacing: 2,
        direction: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
    }
}
