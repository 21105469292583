import { Page401 } from "./401";
import { Page404 } from "./404";
import { DrawerHeader } from "./DrawerHeader";
import { DrawerLeft } from "./DrawerLeft";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { LocaleSelector } from "./LocaleSelector";
import { UI } from "./UI";
import { UserMenu } from "./User/UserMenu";
import { UserToolbar } from "./User/UserToolbar";
import { Main } from "./main";

export default UI

export {
    DrawerHeader,
    DrawerLeft,
    Footer,
    Header,
    LocaleSelector,
    Main,
    Page401,
    Page404,
    UI,
    UserMenu,
    UserToolbar,
}