import { Avatar, Backdrop, Box, Card, Checkbox, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { ComponentWithElementProps } from '../types'
import { CheckBoxRounded } from '@mui/icons-material'

const CheckList = ({ element }: ComponentWithElementProps) => {
    const [selected, toggleSelected] = React.useReducer((ids: string[], id: string): string[] => ids.includes(id) ? ids.filter(f => f !== id) : [...ids, id], [])
    return (
        <List
            {...element.props}
            subheader={element.titre
                ? <Box display={'flex'}>
                    <Typography variant={'h5'} sx={{ mb: 3 }}>
                        {element.titre} {selected.length === element.childs.length && <CheckBoxRounded />}
                    </Typography>
                </Box>
                : undefined}>
            {element.childs.map(child => <MuiItem key={child.id} element={child} selected={selected} handleSelect={toggleSelected} />)}
        </List>
    )
}

interface MuiItemProps extends ComponentWithElementProps {
    selected: string[]
    handleSelect: (id: string) => void
}
const MuiItem = ({ element, selected, handleSelect }: MuiItemProps) => {
    const [open, toggleOpen] = React.useReducer(o => !o, false)
    return (
        <ListItem
            secondaryAction={<Checkbox checked={selected.includes(element.id)} onChange={e => { e.preventDefault(); e.stopPropagation(); handleSelect(element.id) }} />}
        >
            {element.props.src &&
                <ListItemAvatar sx={{ cursor: 'pointer' }} onClick={toggleOpen}>
                    <Avatar src={`/download/${element.props.src}`} variant={'rounded'} />
                </ListItemAvatar>}
            <ListItemText {...element.props} sx={{ mr: 2 }} inset={!element.props.src} />
            <Backdrop
                sx={{ zIndex: 400000 }}
                open={open}
                onClick={toggleOpen}>
                <Card>

                    <img src={`/download/${element.props.src}`} style={{ minWidth: '60vw', maxHeight: '90vh' }} alt="" />
                </Card>
            </Backdrop>
        </ListItem>
    )
}

export default CheckList