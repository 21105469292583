import React from 'react'

import { IconButton } from '@mui/material'
import { PlayArrow, Pause } from '@mui/icons-material'

import { VideoPlayerContext } from '..'

export const PlayPauseButton = () => {
    const { status, dispatch } = React.useContext(VideoPlayerContext)
    return (
        <IconButton size={status === 'play' ? undefined : 'small'} onClick={dispatch.togglePlay}>
            {status !== 'play' ? <PlayArrow /> : <Pause fontSize='small' />}
        </IconButton>
    )
}
