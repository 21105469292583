import React from "react"
import { DicLangType, DicWordType, useLangContextType, useLangLocaleType, useLangType } from "./Types"

export const LangContext = React.createContext<useLangContextType>({} as useLangContextType)

const useLang: useLangType = (locale) => {

    const [lang, setLang] = React.useState<useLangLocaleType>(locale ?? 'fr-FR')
    const [dics, setDics] = React.useState<DicLangType[]>()

    const trad = React.useMemo(() => {
        let arr: { [k: string]: string } = {}
        dics?.find(d => d.name === lang)?.words.map(w => arr[w.name] = w.value)
        return arr
    }, [dics, lang])

    const byKey = React.useMemo(() => (key: string) => {
        return dics?.map(l => l.words.filter((w) => w.name === key).map(w => ({ ...w, lang: l }))).flat()
    }, [dics])

    const keys = React.useMemo(() => {
        return (dics ?? []).map((dic) => dic.words.map(w => w.name)).flat().filter((w, i, s) => s.indexOf(w) === i)
    }, [dics])

    const availableLocales = React.useMemo(() => dics?.map(d => d.name) ?? [], [dics])

    const updateWords = React.useMemo(() => (val: DicWordType | DicWordType[]) => {
        const value = Array.isArray(val) ? val : [val]
        setDics(prev =>
            prev?.map(dic => ({
                ...dic,
                words: dic.words.map(w =>
                    value.find(v => v.dicLangId === dic.id)?.id === w.id
                        ? value.find(v => v.dicLangId === dic.id) ?? w
                        : w)
            }))
            ??
            []
        )
    }, [dics])

    const updateLocale = (l: useLangLocaleType) => setLang(l)

    const reload = () => fetch(`/api/lang`)
        .then(res => res.json())
        .then(setDics)

    React.useEffect(() => {

        fetch(`/api/lang`)
            .then(res => res.json())
            .then(setDics)
    }, [])

    return {
        actualLocale: lang ?? 'fr-FR',
        availableLocales,
        dics,
        setLang: updateLocale,
        trad,
        keys,
        byKey,
        updateWords,
        reload,
    }
}

export default useLang