import React from 'react'
import { ComponentWithElementProps } from '../types'
import { Button } from '@mui/material'

const ExternalLink = ({ element }: ComponentWithElementProps) => {
    return (
        element.value
            ? <Button component={'a'} href={element.value} target="_blank" rel="noreferrer">{element.titre ?? element.value}</Button>
            : <></>
    )
}

export default ExternalLink