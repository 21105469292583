import { Subject, Title } from "@mui/icons-material";

export const TitreConfig = {
    type: 4,
    label: 'Titre',
    icon: Title,
    allowedBy: [2],
    autoConfig: true,
    defaultProps: {
        gutterBottom: true,
        variant: 'h4',
        align: 'left'
    }
}

export const TexteConfig = {
    type: 5,
    label: 'Texte',
    icon: Subject,
    allowedBy: [2, 9],
    autoConfig: true,
    defaultProps: {
        gutterBottom: true,
        variant: 'body1',
        align: 'left'
    }
}